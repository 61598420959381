/* global successDialog proTip */
SC.utils = (function () {
  var api = {};

  $('.top-nav-campaign-link').on('click', function () {
    SC.analytics.mp.logEvent('Challenge_Fall18 - Click Nav Link', 'navigation');
  });

  api.debounce = function (delay, fn) {
    var timer = null;
    return function () {
      var context = this;
      var args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  };

  api.is_ie = function () {
    var ua = window.navigator.userAgent;
    var allMsie = ua.indexOf('MSIE ');

    if (allMsie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      return true;
    }
    return false;
  };

  api.is_firefox = function () {
    if (navigator.userAgent.match(/Mozilla/i)) {
      return true;
    }
    return false;
  };

  api.is_mobile_device = function () {
    if ($.browser.mobile) {
      return true;
    }
    return false;
  };

  api.capitalizeFirstLetters = function (word) {
    return word.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  api.toggleFavoriteInstructor = function (instructorId, enabled) {
    $.ajax({
      url: '/instructor/favorite/',
      method: 'POST',
      dataType: 'json',
      data: {
        instructor: instructorId,
        enabled: enabled,
        csrf_token: window.soulcycle.noncePool.pop()
      }
    });
  };


  // Checks the current
  api.window_visibility_checker = function () {
    api.window_visibility_status = 'visible';
    var hidden = 'hidden';

    // Standards:

    if (hidden in document) {
      document.addEventListener('visibilitychange', onchange);
    } else if ((hidden = 'mozHidden') in document) {
      document.addEventListener('mozvisibilitychange', onchange);
    } else if ((hidden = 'webkitHidden') in document) {
      document.addEventListener('webkitvisibilitychange', onchange);
    } else if ((hidden = 'msHidden') in document) {
      document.addEventListener('msvisibilitychange', onchange);
    } else if ('onfocusin' in document) { // IE 9 and lower:
      document.onfocusin = document.onfocusout = onchange;
    } else {  // All others:
      window.onpageshow = window.onpagehide
                = window.onfocus = window.onblur = onchange;
    }


    function onchange(_evt) {
      var v = 'visible';
      var h = 'hidden';
      var evtMap = {
        focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
      };

      var evt = _evt || window.event;
      if (evt.type in evtMap) {console.log(evtMap[evt.type]);} else {
        var currentStatus = this[hidden] ? 'hidden' : 'visible';
        api.window_visibility_status = currentStatus;

        if (currentStatus === 'visible') {$('body').trigger('tab_visible');}
      }
    }
  };

  /*
    @function bubbleIndicator
    Update body class with the new bubbleIndicator based on rider data
    **/
  api.updateBubbleIndicatorClass = function updateBubbleIndicatorClass(regionTitle, seriesMenu, headerMenu) {
    var bubbleIndicatorEndpoint = '/profile/bubbleindicator/';

    // Send studio id if on studio detail or studio schedule page
    if ($('.studio_detail .studio-name, .studio_find_class .studio-title').length) {
      var studioId = $('.studio_detail .studio-name span, .studio_find_class .studio-title').data('studio-id');
      bubbleIndicatorEndpoint = bubbleIndicatorEndpoint + '?studio-id=' + studioId;
    }

    if ($('.studio_class_select_bike').length) {
      var classId = $('#class-information, #crosshairs').data('class-id');
      bubbleIndicatorEndpoint = bubbleIndicatorEndpoint + '?class-id=' + classId;
    }

    $.ajax({
      url: bubbleIndicatorEndpoint,
      success: function (data) {
        // Update region title
        $('.rider-region-title').html(regionTitle);

        $('body').removeClass(data.bubble_body_class.indicator_classes)
          .addClass(data.bubble_body_class.current_classes);

        // Update series menu and profile series menu
        $('.nav1').replaceWith(seriesMenu);

        if (headerMenu !== 'undefined') {
          $('.profile-series-menu-container').replaceWith(headerMenu);
        }
      }
    });
  };

  /**
   * This event gets the product title for an mParticle createProduct event.
   * Takes a category of product, the size and title and determines the correct title.
   *
   * @param {string } category product category
   * @param {string} size size
   * @param {string} title product title
   * @returns {string} title
   */
  api.getProductTitle = function getProductTitle(category, size, title) {
    // If the product is not a series just use the title.
    if (category !== 'Series') {
      return title;
    }

    // Set title as # of classes if product is a series to match app mparticle data.
    return (String(size) === '1') ?
      'A single class.' :
      'A set of ' + size + ' classes.';
  };

  /**
   * Determine the correct sku for an mParticle createProduct event.
   * If the product is a series, add the size on to the end of the sku.
   * Otherwise, just return the sku.
   * @param {string} category product category
   * @param {string} sku product sku
   * @param {string} size product size
   * @returns {string} sku
   */
  api.getSku = function getSku(category, sku, size) {
    // If it's a series, then return the sku plus the size, e.g. SOUL-NYC-5.
    if (category === 'Series') {
      return sku + '-' + size;
    }

    return sku;
  };

  /**
   * Get the baseSku for a product.
   * If its a series, return the sku, otherwise return the slug.
   *
   * @param {string} category product category
   * @param {string} sku - Sku for a product (both series and other).
   * @param {string} slug - Base sku for a product (not a series).
   * @returns {string} base sku
   */
  api.getBaseSku = function getBaseSku(category, sku, slug) {
    if (category === 'Series') {
      return sku;
    }

    return slug;
  };

  /**
   pollAvailability
   Polling for reservations on class booking pages for Cycle
   @param {object} $classContainer Selector with a class-id data object
   @returns {void}
   **/
  api.pollAvailability = function ($classContainer) {
    if (SC.utils.window_visibility_status === 'hidden') {
      return;
    }

    var url = '/find-a-class/poll-availability/';
    var classId = $classContainer.data('class-id');

    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'json',
      data: {'class': classId},
      // skipped eslint check since we are using this parameter in line 250 'arguments'
      // eslint-disable-next-line no-unused-vars
      success: function (data, textStatus, jqXHR) {
        if (typeof data.reservations !== 'undefined') {
          // reservation_info is undefined when a class is a Cycle Class
          var reservations = data.reservations;
          var bookedCount = reservations.reservation_info.booked_count;
          var $seats = $('.select-bike-container .seat');

          $('#crosshairs').data('booked-bikes', bookedCount);

          $seats.each(function () {
            var $seat = $(this);
            var seatId = $seat.data('id');

            if (reservations.hasOwnProperty(seatId)) {
              var newClass = reservations[seatId];
              var bookedClassText = newClass === 'booked' ? ' by you' : '';

              $seat.removeClass('open booked taken').addClass(newClass).attr({
                'aria-disabled': newClass === 'taken',
                'aria-label': 'Seat ' + $seat.data('value') + ' is ' + newClass + bookedClassText,
                'aria-selected': newClass === 'booked'
              });
            } else if (!$seat.hasClass('fan') && !$seat.hasClass('instructor') && !$seat.hasClass('door')) {
              $seat.removeClass('booked taken').addClass('open').attr({
                'aria-disabled': false,
                'aria-label': 'Book seat ' + $seat.data('value'),
                'aria-selected': false
              });
            }
          });
        } else {
          console.log('Poll availability XHR error', arguments);
        }
      },
      // skipped eslint check since we are using this parameter in line 256 'arguments'
      // eslint-disable-next-line no-unused-vars
      error: function (jqXHR, textStatus, errorThrown) {
        console.log('Poll availability XHR error', arguments);
      }
    });

    return;
  };

  /**
   * changeRegion
   * Actions taken when clicking changing region.
   * @param {data} region data passed from event.
   * @param {boolean} _windowReload reload or not
   * @param {function} callBack callback
   * @returns {void}
   **/
  api.changeRegion = function changeRegion(region, _windowReload, callBack) {
    var $form = $('.change-region-form');
    var windowReload = _windowReload || false;

    $form.ajaxSubmit({
      dataType: 'json',
      data: {targetRegion: region},
      success: function (data) {
        $.updateAllCsrfTokens();
        if (data.success) {
          $('.nav0 .current-region-title').empty().text(data.current_region.region_title);
          var $studioList = $('.nav0 .find-a-class-row');
          $studioList.empty();

          for (var i = 0; i < data.current_region.region_list.length; i++) {
            var studio = data.current_region.region_list[i];

            var $a = $('<a/>');
            $a.attr('href', studio._links.self);
            $a.text(studio.title);

            var $li = $('<li/>');
            $li.html($a);
            $studioList.append($li);
          }

          var $region = $('.region-select-row');
          $region.find('.active').removeClass('active');
          var $selectedRegion = $region.find('[data-id="' + data.current_region.region_id + '"]');
          $selectedRegion.addClass('active');

          // setting last region to mparticle
          SC.analytics.mp.userEvent('attribute', $selectedRegion.first().text().trim(), 'Last Region Selected');

          $('.active-region .active-region-title').empty().text(data.current_region.region_title).parent('.active-region').trigger('click');

          if (data.clear_cart) {
            SC.analytics.mp.clearCart();
          }

          /**
                     * Special Exceptions the page needs to be reloaded
                     */
          var $body = $('body');

          if (windowReload || $body.hasClass('instructors_index') || $body.hasClass('studio_list') || $body.hasClass('series_purchase')) {
            window.location.reload();
          }

          // If they are not logged in just update region title.
          // Otherwise, use the callBack to update the series bubble indicators.
          if (!soulcycle.rider.id) {
            $('.rider-region-title').html(data.current_region.region_title);
          } else {
            callBack(data.current_region.region_title, data.seriesMenu, data.profileSeriesMenu);
          }
        } else if (typeof data.message !== 'undefined') {
          confirmation(undefined, data.message);
        } else if (data.fieldErrors.length > 0) {
          confirmation(undefined, data.fieldErrors.join('\n'));
        } else {
          confirmation(undefined, genericErrorMessage);
        }
      },
      error: function () {
        $(this).updateCsrfToken();
        confirmation(undefined, genericErrorMessage);
      }
    });
  };

  api.get_url_chunk = function (_f, e) {
    var d = window.location.pathname.split('/');
    var f = _f;

    f++;
    var h = '';
    for (var g = 0; g < h.length; g++) {
      if (h[g] && h[g].length > 0) {
        f++;
      }
    }
    if (d.length <= f) {
      return e;
    }
    var j = d[f];
    if (!j || j.length === 0) {
      return e;
    }
    if (typeof e === 'number') {
      return parseInt(j, 10);
    }
    return j;
  };

  api.toggleFavoriteStudio = function (studioId, enabled) {
    $('.instructors-by-studio-sub-region a[data-studio=' + studioId + ']').toggleClass('bookmarked');
    $.ajax({
      url: '/studio/favorite/',
      method: 'POST',
      dataType: 'json',
      data: {
        studio: studioId,
        enabled: enabled,
        csrf_token: window.soulcycle.noncePool.pop()
      }
    });
  };

  api.copyTextToClipboard = function (text) {
    var tempElm = document.createElement('textarea');
    var success;
    var html = $('html');

    tempElm.display = 'none';
    tempElm.style.position = 'absolute';
    // Set top to current scroll height scroll change on focus
    tempElm.style.top = html.scrollTop();
    document.body.appendChild(tempElm);
    tempElm.textContent = text;
    tempElm.focus();
    tempElm.setSelectionRange(0, tempElm.value.length);
    try {
      success = document.execCommand('copy');
    } catch (e) {
      success = false;
    }
    document.body.removeChild(tempElm);

    return success;
  };

  api.popupCenter = function (pageUrl, title, width, height) {
    var left = (screen.width / 2) - (width / 2);
    var top = (screen.height / 2) - (height / 2);

    return window.open(pageUrl, title,
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, ' +
            'copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
  };

  /**
   * Returns true if the request is from an `IOS Web view`
   *
   * @return {boolean} the request is from an `IOS Web view`
   */
  api.isIosWebViewRequest = function () {
    return document.cookie.indexOf('SCS_IOS_WEB_VIEW') !== -1;
  };

  /**
   * Returns the platform from where the request was generated.
   *
   * @return {string} current platform
   */
  api.getCurrentPlatform = function () {
    return api.isIosWebViewRequest() ? 'iOS' : 'Web';
  };

  /**
   * Set cookie
   *
   * @param {string} name cookie name
   * @param {string} value cookie value
   * @param {number} [expDays] cookie expiration time in days
   * @returns {void}
   */
  api.setCookie = function setCookie(name, value, expDays) {
    var expiration;

    switch (expDays) {
    case undefined:
      expiration = ';expires=Tue, 19 Jan 2038 03:14:07 GMT'; // default cookie expiration to maximum possible date (Y2038)
      break;
    case 0:
      expiration = ''; // cookie will expire at end of session if expDays is 0
      break;
    default:
      var date = new Date();
      date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
      expiration = ';expires=' + date.toUTCString();
    }

    document.cookie = name + '=' + value + expiration + ';path=/;';
  };

  /**
   * Delete cookie
   *
   * @param {string} name cookie name
   * @returns {void}
   */
  api.deleteCookie = function setCookie(name) {
    api.setCookie(name, '', -1);
  };

  /**
   *
   * Returns `true` if the shipping address contains a PO Box address
   *
   * @param {object} $addressInput shipping address
   * @param {object} $address2Input shipping address 2
   * @return {boolean} adyen active
   */
  api.shippingHasAPOBoxAddress = function ($addressInput, $address2Input) {
    var poBoxRegex = new RegExp('[PO.]*\\s?B(ox)?.*\\d+', 'i');


    if (typeof $addressInput.val() !== 'undefined') {
      if (poBoxRegex.test($addressInput.val().toLowerCase())) {
        displayShippingErrorMessage($addressInput);
      }
    }

    if (typeof $address2Input.val() !== 'undefined') {
      if (poBoxRegex.test($address2Input.val().toLowerCase())) {
        displayShippingErrorMessage($address2Input);
      }
    }

    return poBoxRegex.test($addressInput.val()) || poBoxRegex.test($address2Input.val());
  };

  /**
   *
   * Display the `P.O. Boxes` error message
   *
   * @param {object} $addressInput the target node
   * @return {void}
   */
  function displayShippingErrorMessage($addressInput) {
    var errorMessage = 'UPS does not deliver to P.O .Boxes. Please enter another address.';

    $addressInput.addClass('control field-error');
    confirmation(undefined, errorMessage, undefined, 0);

    $addressInput.click(function () {
      $addressInput.removeClass('control field-error');
    });
  }

  return api;
})();

SC.utils.ui = (function () {
  var api = {};
  var headerHeight;

  var init = function () {
    handlers();
    headerHeight = $('.header-container').height();

    api.handleBrokenImages();
  };

  var handlers = function () {
    $('body').delegate('.close_overlay_button', 'click', function (e) {
      e.preventDefault();
      api.close_ui_modal();
    });
  };

  /**
   incrementOrDecrementSeriesMenu
    Increment or Decrement series number of `CYCLE` on menu

    @param {object} bookingResponse - Object data with the success data of reserve or unreserves class.
    @param {string} action - name of the action, could be `increment` or `decrement`.
    @return {bool} - Return true if the series number was changed on the menu.
  **/
  api.incrementOrDecrementSeriesMenu = function (bookingResponse, action) {
    var calculateSeriesActions = {
      'increment': {
        'series': bookingResponse.credits,
        'balance_series': 'series_balance_credited',
        calculate: function (credit, balance) { return credit + balance;}
      },
      'decrement': {
        'series': bookingResponse.debits,
        'balance_series': 'balance_used',
        calculate: function (credit, balance) { return credit - balance;}
      }
    };

    var activityElements = {
      'cycle': {
        '$totalCreditCount': $('.credits.rider-total-credits.desktop_only'),
        '$creditContainers': $('.rider-total-credits'),
        '$sectionRegion': $('.header-user-name .section-region .cycle-series'),
        '$otherRegionsCreditsCount': $('.header-user-name .section-region.unusable-cycle-credits .unusable-credits')
      }
    };

    var activity = 'cycle';

    if (!calculateSeriesActions.hasOwnProperty(action) || typeof calculateSeriesActions[action].series === 'undefined') {
      return false;
    }

    var calculateSeriesSelected = calculateSeriesActions[action];

    if (bookingResponse.hasOwnProperty('activity')) {
      activity = bookingResponse.activity.toLowerCase();
    }

    // Reduce series counter
    var $totalCreditsCount = activityElements[activity].$totalCreditCount;
    var $creditContainers = activityElements[activity].$creditContainers;

    $.each(calculateSeriesSelected.series, function (idx, series) {
      var $seriesCreditsCount = null;
      // TODO: this is a hack, remove this and figure out a better way
      // since we are targetting based on series sku, if the rider is out of the sku, it will not
      // be in any of the divs, so we target it based on whether it's a warrior week or a non-warrior week sku
      if (series.is_warrior_week) {
        $seriesCreditsCount = activityElements[activity].$sectionRegion.filter('.usable_ww_credits');
        // if the action is to unreserve a warrior week (rare occurance), the warrior week bucket will be hidden
        // if we're changing the value of something that's hidden, we should show it
        if ($seriesCreditsCount.parents('.section-region.hide').length === 1) {
          $seriesCreditsCount.parents('.section-region.hide').removeClass('hide');
        }
      } else {
        $seriesCreditsCount = activityElements[activity].$sectionRegion.filter('.usable_credits');
      }

      var $seriesCreditContainer = $($creditContainers.filter('[aria-label]')[0]);
      var $sectionRegionContainer = $($seriesCreditsCount.parents('.section-region')[0]);
      var totalCreditsQuantity = parseInt($totalCreditsCount.text(), 0);
      var seriesCreditsQuantity = parseInt($seriesCreditsCount.text(), 0);
      var currentSeriesValue = series[calculateSeriesSelected.balance_series];

      var $otherRegionsCreditsCount = activityElements[activity].$otherRegionsCreditsCount;

      var creditContainerValue = calculateSeriesSelected.calculate(totalCreditsQuantity, currentSeriesValue);
      var seriesCreditValue = calculateSeriesSelected.calculate(seriesCreditsQuantity, currentSeriesValue);

      // Update `aria-label` for total credits quantity
      if ($seriesCreditContainer.attr('aria-label') !== undefined) {
        var totalCreditsAriaLabel = $seriesCreditContainer.attr('aria-label').replace(totalCreditsQuantity, creditContainerValue);
        $seriesCreditContainer.attr('aria-label', totalCreditsAriaLabel);
      }

      // When the rider is on the Bike map page, then change the region and try to book a bike from a class
      // on a region which is not the current selected one.
      if (bookingResponse.class_region_unselected !== undefined && bookingResponse.class_region_unselected === true) {
        var totalOtherRegionsCreditsQuantity = parseInt($otherRegionsCreditsCount.text(), 0);
        var otherRegionsContainerValue = calculateSeriesSelected.calculate(totalOtherRegionsCreditsQuantity, currentSeriesValue);

        $otherRegionsCreditsCount.text(otherRegionsContainerValue);
      } else {
        // Updates current series credit count
        $seriesCreditsCount.text(seriesCreditValue);

        // Updates current region credit count
        $creditContainers.text(creditContainerValue);

        // Update `aria-label` for series credits quantity
        if ($sectionRegionContainer.attr('aria-label') !== undefined) {
          var seriesCreditsAriaLabel = $sectionRegionContainer.attr('aria-label').replace(seriesCreditsQuantity, seriesCreditValue);
          $sectionRegionContainer.attr('aria-label', seriesCreditsAriaLabel);
        }
      }
    });

    return true;
  };

  api.toggleThrobber = function (_enabled) {
    var $whiteOverlay = $('.white-overlay');

    var enabled = _enabled;
    if (typeof enabled === 'undefined') {
      enabled = true;
    }

    if (enabled) {
      $whiteOverlay.stop().animate({
        opacity: 1
      }, 200).show();
      $('html').css({'cursor': 'wait'});
      return;
    }
    $whiteOverlay.stop().animate({
      opacity: 0
    }, 200, function () {
      $(this).hide();
    });
    $('html').css({'cursor': 'default'});
  };


  // Handle changing all rider available series menus (old account, new SmartProfile, header series menu)
  // When the series transfer active button is toggled in either header or account/profile pages.
  api.updateSeriesTransferMessage = function updateSeriesTransferMessage(seriesData) {
    var series = seriesData.payload.rider_series;
    var riderInfo = seriesData.payload.rider_info;
    var classesPlural = series.transferable_credits > 1 ? 'classes' : 'class';
    var $toggleBtn = $('.switch, .profile-series-menu-transfer__switch').children('input:hidden');
    var transferMessageTitle;
    var transferMessageBody;

    if (seriesData.payload.toggle) {
      // Change series transfer messages to on state.
      transferMessageTitle = 'Transferring ' + series.transferable_credits + ' ' + classesPlural;
      transferMessageBody = 'from ' + series.transferable_regions +
        '. These classes may cost more than your current region.';
    } else {
      // Change series transfer messages to off state.
      transferMessageTitle = 'Turn on Class Transfer';
      transferMessageBody = 'to use your ' + series.transferable_credits + ' ' + classesPlural +
        ' from ' + series.transferable_regions + ' in the ' + riderInfo.region_title + ' region.';
    }

    // Toggle the state of the series transfer switch elements.
    $('.on-off-toggle').toggleClass('on').toggleClass('off');
    $('.profile-series-menu-transfer__switch').toggleClass('profile-series-menu-transfer__switch--active');
    $('.profile-series-menu-transfer__switch__knob').toggleClass('profile-series-menu-transfer__switch__knob--active');

    // Toggle the visibility of the series transfer credits bubble.
    $('form #left-col').toggleClass('hide');
    $('.profile-series-menu-transfer__credits').toggleClass('profile-series-menu-transfer__credits--hidden');

    // Change usable region credits count to include transfer credits.
    $('.usable_credits, .profile-series-menu__credits__count--usable-cycle').html(series.usable_credits);
    $('.rider-cycle-credit').html(series.usable_credits);

    // Update series transfer messages.
    $('.class-transfer-message, .profile-series-menu-transfer__switch__message__title').html(transferMessageTitle);
    $('.transfer-message-first, .profile-series-menu-transfer__switch__message__body').html(transferMessageBody);

    // Change the form input so the next toggle series transfer form submit is correct.
    $toggleBtn.each(function () {
      $(this).val($(this).val() === 'on' ? 'off' : 'on');
    });
  };

  api.handleBrokenImages = function () {
    $('img').error(function () {
      $(this).attr('src', '/assets/images/wheel_missing_image.png');
    });
  };

  /**
   * Function to setup the Pro Tip Modal on any page.
   * Uses pro_tip.js to build a ProTip and proTip.confirm() to populate the text.
   * @param {string} _buttonText text
   * @param {string} _headerText text
   * @param {string} _mainText text
   * @returns {void}
   */
  api.displayProTipModal = function (_buttonText, _headerText, _mainText) {
    // Build the content for text and button containers.
    var buttonText = _buttonText || 'Dismiss';
    var headerText = _headerText || 'PRO TIP:';
    var mainText = _mainText || 'PRO TIP';

    var parameters = {
      buttons: {
        dismiss: {
          text: buttonText
        }
      },
      textContainers: {
        header: {
          text: headerText
        },
        main: {
          text: mainText
        }
      }
    };

    // Setup Pro Tip
    SC.proTip = proTip();
    SC.proTip.confirm(parameters);
  };

  /**
    * Function to setup the Success Dialog Modal on any page.
    * Uses success-dialog.js to build a Success Dialog and successDialog.confirm() to populate the text.
    * @param {string} _buttonText text
    * @param {string} _headerText text
    * @param {string} _mainText text
    * @returns {void}
    */
  api.displaySuccessDialog = function (_buttonText, _headerText, _mainText) {
    // Build the content for text and button containers.
    var buttonText = _buttonText || 'Done';
    var headerText = _headerText || 'Congrats!';
    var mainText = _mainText || 'You\'ve made a reservation! Be on the lookout for an email!';

    var parameters = {
      buttons: {
        dismiss: {
          text: buttonText
        }
      },
      textContainers: {
        header: {
          text: headerText
        },
        main: {
          text: mainText
        }
      }
    };

    // Setup Success Dialog for bike-map and set focus to the close button.
    SC.successDialog = successDialog();
    SC.successDialog.confirm(parameters);
    $('.success-dialog__close').focus();
  };

  /**
    * Check if a keyup event should be handled or not.
    * If its a keyup event, return true only if its an Enter of Spacebar key code.
    * @param {object} e event
    * @returns {boolean} handle or not
    */
  api.handleKeyupEvent = function handleKeyupEvent(e) {
    return !(e.key !== 'Enter' && e.key !== ' ' && e.type === 'keyup');
  };

  /**
   * Scroll the page to the element that was clicked on.
   *
   * @param {object} e event
   * @returns {void}
   */
  api.handleScrollEvent = function handleScrollEvent(e) {
    e.preventDefault();

    if (!api.handleKeyupEvent(e)) {
      return;
    }

    // The href of the current element should be an ID that can be used as a JQuery selector.
    $('html, body').animate({
      scrollTop: ($($(this).attr('href')).offset().top - headerHeight)
    }, 500);
  };

  init();
  return api;
})();

SC.utils.pubsub = (function () {
  var api = {};

  /**
   * Subscribe the `handler` to the given `eventName`
   *
   * @param {string} eventName event to subscribe to
   * @param {Function} handler handler for the event
   * @returns {void}
   */
  api.subscribe = function (eventName, handler) {
    $(window).bind(eventName, handler);
  };

  /**
   * Publish the `eventName` with provided `params` if any
   *
   * @param {string} eventName event to publish
   * @param {*} [params] parameters to attach to the published event
   * @returns {void}
   */
  api.publish = function publish(eventName, params) {
    $(window).trigger(eventName, params);
  };

  return api;
})();

SC.utils.validation = (function () {
  var api = {};

  /**
   * Verify that the `email` string is a valid email address
   *
   * @param {string} email email address to validate
   * @returns {boolean} valid email or not
   */
  api.isEmail = function (email) {
    var emailRegex = /^("([ !\x23-\x5B\x5D-\x7E]*|\\[ -~])+"|[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*)@([0-9a-z\u00C0-\u02FF\u0370-\u1EFF]([-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,61}[0-9a-z\u00C0-\u02FF\u0370-\u1EFF])?\.)+[a-z\u00C0-\u02FF\u0370-\u1EFF][-0-9a-z\u00C0-\u02FF\u0370-\u1EFF]{0,17}[a-z\u00C0-\u02FF\u0370-\u1EFF]$/i;

    return emailRegex.test(email);
  };

  return api;
})();

/**
 * Display a custom notification banner for the Flash Sale
 *
 * @return {void}
**/
SC.utils.displayFlashSaleBanner = function () {
  if (localStorage.getItem('flashSale-03-23-2020-ConfirmationClose')) {
    return;
  }

  confirmation(
    null,
    '<span class="flash-sale-message"><a href="/shop/the-big-sale/"><span class="flash-sale-big-sale">The Big Sale</span></a></span>',
    null,
    0
  );

  var $closeButton = $('button.confirmation-close-btn');
  var $closeButtonImage = $closeButton.find('> img');
  var $productContainer = $('.shop-retail-products,.product-container');

  // Add the classes to update the close button icon and it's position
  $closeButton.addClass('flash-sale-btn-position');
  $closeButtonImage.attr('src', '/assets/images/flash-sale/icon-x.svg').addClass('flash-sale-close-btn');
  $productContainer.addClass('flash-sale-margin');

  $closeButton.on('click', function () {
    $productContainer.removeClass('flash-sale-margin');
    localStorage.setItem('flashSale-03-23-2020-ConfirmationClose', true);
  });
};

/**
 * Check if an element is overflowed vertically
 * @returns {boolean} overflowed or not
 */
$.fn.overflown = function () {var e = this[0]; return e.scrollHeight > e.clientHeight;};
