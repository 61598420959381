
/**
 * Call on a <form /> element.
 * Adds or updates one overall Form error message
 * @param  {string} message The overall error message.
 * @returns {object} `this` object
 */
$.fn.formError = function (message) {
  var $formError = $(this).children('.form-error:last');
  function renderNewError() {
    $formError.html(message);
    $formError.fadeIn('fast');
  }
  if ($formError.length) {
    $formError.fadeOut('fast', function () {
      renderNewError();
    });
  } else {
    $formError = $('<p class="form-error"></p>');
    $formError.appendTo($(this));
    renderNewError();
  }
  return this;
};

/**
 * Call on a <form /> element.
 * Removes form-wide error messages.
 * Removes all inline field error messages from child elements.
 * @param {function} callback callback
 * @returns {object} `this` object
 */
$.fn.clearFormErrors = function () {
  $(this).find('.form-error').remove();
  $(this).find('span.error-message').remove();
  $(this).find('label.field-error, input.field-error, select.field-error').removeClass('field-error');
  $(this).find('.has-field-error').removeClass('has-field-error');
  return this;
};

/**
 * Call on a form child element.
 * Adds or updates one Form field error message
 * @param  {string} message The field's inline error message.
 * @returns {object} `this` object
 */
$.fn.fieldError = function (message) {
  var $fieldError = $(this).closest('label.field-error');
  if (!$fieldError.length) {
    var $fieldLabel = $(this).closest('label');
    if ($fieldLabel.length) {
      $fieldLabel.addClass('field-error');
      $fieldError = $fieldLabel;
    } else {
      var $currentInputParent = $(this)
        .parent()
        .append('<label class="field-error"/>');
      $fieldError = $currentInputParent.find('label.field-error');
    }
  }
  var val = $(this).val();
  var $errorSpan = $fieldError.find('.error-message');
  if ($errorSpan.length) {
    $errorSpan.fadeOut('fast', function () {
      $errorSpan.html(message);
      $errorSpan.fadeIn('fast');
    });
  } else {
    var appendHtml = '<span style="display:none;" role="alert" class="error-message">' + message + '</span>';
    $fieldError.html($fieldError.html() + appendHtml);
    var $fieldInput = $(this);
    if ($fieldInput.attr('type') !== 'file') {
      $fieldInput.val(val);
    }
    var $errorMessage = $fieldError.find('.error-message');
    if ($fieldInput.attr('type') !== 'checkbox') {
      // Prevent the error message from stretching beyond the field's space laterally.
      var inputWidth = parseInt($fieldInput.width(), 0);
      var inputPadding = parseInt($fieldInput.css('padding-left').slice(0, -2), 0);
      var inputBorderWidth = parseInt($fieldInput.css('border-width').slice(0, -2), 0);
      var errorLeftPadding = parseInt($errorMessage.css('padding-left').slice(0, -2), 0);
      var errorMessageWidth = (inputPadding * 2) + (inputBorderWidth * 2) + inputWidth - errorLeftPadding;
      $errorMessage.css('width', errorMessageWidth);
    }
    $errorMessage.fadeIn('fast');
  }

  $(this).parent().addClass('has-field-error');
  return this;
};

/**
 * Call on a form child element AFTER injecting inline field errors.
 * Guarantees that label.field-error's which contain file inputs are given
 * the class "file", thus: label.field-error.file
 * @returns {void}
 */
$.fn.flagFieldErrorsWithFileInputs = function () {
  var $form = $(this);
  $form.find('label.field-error:not(.file) input[type=file]').each(function () {
    $(this).closest('label').addClass('file');
  });
};

/**
 * Call on a <form /> element.
 * Delegates server response of field errors to the corresponding form inputs.
 * @param {array} errors errors
 * @returns {void|object} `this` object
 */
$.fn.fieldErrors = function (errors) {
  if (!errors) {
    return;
  }
  var $form = $(this);
  $.each(errors, function (fieldName, message) {
    var $field = $form.find('[name=' + fieldName + ']');
    if ($field.length) {
      $field.fieldError(message);
    }
  });

  return this;  
};
