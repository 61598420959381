SC.modal = (function () {
  var api = {};

  var init = function () {
    bindEvents();
  };

  var bindEvents = function () {
    var gotScript = false;

    $('#siya-request-btn').click(function () {
      $('.contact-us-container').hide();
      $('.siya-container').show();
      $('.contact-us-close-icon').css('z-index', '100');
      if (!gotScript) {
        $.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDX830GbwAuXtufaQuqkOh8Ln7IN1gvmfA&libraries=places', function () {
          initAutocomplete();
        });
        gotScript = true;
      }
    });

    $('#contact-us-modal').click(function (e) {
      e.preventDefault();
      $('.contact-us-modal').show();
      $('body').addClass('modal-open');
      $('.white-overlay').css('z-index', '998');
      $('.white-overlay').addClass('opaque');
    });

    $('.white-overlay').on('click', function () {
      $('.white-overlay').css('z-index', '');
      $('.white-overlay').removeClass('opaque');
      $('body').removeClass('modal-open');
      resetModal();
      resetForm();
    });

    $('.contact-us-close-icon').click(function (e) {
      e.preventDefault();
      $('.white-overlay').removeClass('opaque');
      $('body').removeClass('modal-open');
      resetModal();
      resetForm();
    });

    $('#location_text').on('keydown', function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
  };

  var autocomplete;
  var componentForm = {
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'short_name'
  };

  var initAutocomplete = function () {
    var input = document.getElementById('location_text');
    var options = {
      types: ['geocode']
    };

    autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed', fillInAddress);
  };

  function fillInAddress() {
    var place = autocomplete.getPlace();

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        document.getElementById(addressType).value = val;
      }
    }

    $('#siya-lat').val(place.geometry.location.lat());
    $('#siya-lng').val(place.geometry.location.lng());

    setupSiyaHiddenFieldsClear();
  }

  // override default jquery require validator message
  $.extend($.validator.messages, { required: 'Oops! Please fill out your info above!' });

  // Overriding default jquery email validator to catch tld
  $.validator.addMethod(
    'email',
    function (value, element) {
      return this.optional(element) || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(value);
    },
    'Please enter a valid email address.'
  );

  $('#siya-form').validate({
    submitHandler: function (form) {
      siyaAjaxSubmit(form);
      return false;
    }
  });

  var siyaAjaxSubmit = function (form) {
    var data = {};

    $.each($(form).children(), function (index, value) {
      var input = $(value);
      if (input.val()) {
        data[input.attr('id')] = input.val();
      }
    });

    $.ajax({
      method: 'post',
      url: $(form).attr('action'),
      data: data
    }).done(function () {
      $('.white-overlay').css('z-index', '');
      $('.white-overlay').removeClass('opaque');
      confirmation(undefined, "Thanks rockstar, you're all set! See you on a bike... soon!");
      resetForm();
      resetModal();
      $(form).updateCsrfToken();
    }).fail(function () {
      confirmation(undefined, 'Oops! Please re-submit your request.');
      $(form).updateCsrfToken();
    });
  };

  var resetSiyaHiddenInputs = function () {
    $('#siya-form .siya-hidden').val('');
  };

  var setupSiyaHiddenFieldsClear = function () {
    $('#location_text').on('keydown', function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      } else if (e.keyCode === 8 || e.keyCode === 46) {
        resetSiyaHiddenInputs();
      }
    });
  };

  // Contact form methods
  var resetForm = function () {
    $('#siya-form').trigger('reset');
    $('#siya-form input:invalid').val('');
    resetSiyaHiddenInputs();
    resetSiyaHandler();
    $('#siya-form input,select').removeClass('error');
    $('#siya-form label').remove();
  };

  var resetModal = function () {
    $('.siya-container').hide();
    $('.contact-us-container').show();
    $('.contact-us-modal').hide();
  };

  var resetSiyaHandler = function () {
    $('#location_text').unbind('keydown');
  };

  init();
  return api;
})();
