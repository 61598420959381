'use strict';

SC.cookieBanner = (function () {
  var api = {};

  var cookieBanner = document.querySelector('#cookie-banner');
  if (!cookieBanner) {
    return api;
  }

  var acceptButton = cookieBanner.querySelector('#cookie-banner-button');

  /**
    * Sets the cookie to 1 and removes the cookie banner
    *
    * @param {object} event for the button.
    * @returns {void}
    */
  function acceptCookiePolicy(event) {
    event.preventDefault();
    SC.utils.setCookie('ACCEPTED_COOKIE_POLICY', '1');

    cookieBanner.style.display = 'none';
    acceptButton.removeEventListener('click', this);
  }

  /**
    * Attaches event lister to cookie banner.
    *
    * @returns {void}
    */
  function init() {
    if (cookieBanner &&  acceptButton) {
      acceptButton.addEventListener('click', acceptCookiePolicy);
    }
  }

  init();
  return api;
})();
