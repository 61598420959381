SC.analytics = {};

SC.analytics.branch = (function () {
  var api = {};
  api.currencyCode = '';

  var init = function () {
    var branchScript = $('#branch-script');
    var branchKey = branchScript.data('branch-key');

    if (!checkForBranch()) {
      return;
    }

    window.branchLoaded = $.Deferred();

    branch.init(branchKey, function (err) {
      if (!err) {
        window.branchLoaded.resolve();
      } else {
        window.branchLoaded.reject(err);
      }
    });
  };

  /**
   * simple check to see if branch is defined.
   * since we're tracking events on user action, if branch has not been defined
   * by the time a login/signup event is fired, then something went wrong
   * with branch.
   * @returns {boolean} - whether or not branch is defined
   */
  var checkForBranch = function () {
    return typeof branch !== 'undefined';
  };

  /**
   * wrapper for the branch logEvent method
   *
   * @param {string} eventName the event name to track
   * @returns {void}
   */
  api.track = function (eventName) {
    api.sendEvent(eventName);
  };

  /**
   * using information that is setup for mParticle, sanitize the data
   * so that it can be formatted the way branch expects it.
   *
   * @param {object} attributes the transaction attributes of a purchase
   * @return {object} attr the cleaned up attributes
   */
  api.sanitizeAttributes = function (attributes) {
    var attr = {};

    attr.transaction_id = attributes.Id.toString();
    attr.revenue = parseFloat(attributes.Revenue);
    attr.shipping = parseFloat(attributes.Shipping);
    attr.affiliation = attributes.Affiliation || '';
    attr.tax = parseFloat(attributes.Tax);
    attr.currency = this.currencyCode;

    return attr;
  };

  /**
   * using information that is setup for mParticle, sanitize the data
   * for each product so that it can be formatted the way branch expects it.
   *
   * @param {array} products an array of products from the current purchase
   * @return {array} sanitizedProducts array of products with sanitized data for branch
   */
  api.sanitizeProducts = function (products) {
    var sanitizedProducts = [];

    for (var i = 0; i < products.length; i++) {
      var attributes = {};
      var product = products[i];

      attributes.$sku = product.Sku;
      attributes.$product_name = product.Name;
      attributes.$price = parseFloat(product.Price);
      attributes.$quantity = parseInt(product.Quantity, 10);
      attributes.$category = product.Category || '';

      sanitizedProducts.push(attributes);
    }

    return sanitizedProducts;
  };

  /**
   * wrapper for the branch track commerce event
   *
   * @param {object} transactionAttributes an object of transaction attributes that are setup for use with mParticle.
   * @param {array} formattedProducts an array of objects containing product information
   * @returns {void}
   */
  api.trackCommerceEvent = function (transactionAttributes, formattedProducts) {
    var attributes = api.sanitizeAttributes(transactionAttributes);
    var products = api.sanitizeProducts(formattedProducts);

    api.sendEvent('PURCHASE', attributes, products);
  };

  /**
   * sendEvent acts a wrapper around the branch.logEvent method
   *
   * @param {string} eventName - the event name
   * @param {object} data an optional object containing event and custom data
   * @param {object} items optional object containing content items
   * @returns {void}
   */
  api.sendEvent = function (eventName, data, items) {
    var customData = data || {};
    var contentItems = items || [];

    window.branchLoaded.done(function () {
      branch.logEvent(eventName, customData, contentItems);
    }).fail(function (err) {
      console.log(err);
    });
  };

  /**
   * wrapper for the branch setIdentity method.
   * we need to ensure that the identity is set before we fire off any callbacks to track
   * events.
   *
   * @param {int} id the rider id to set identity for
   * @param {function} callback the method to call after setIdentity completes
   * @returns {function} promise
   */
  api.setIdentity = function (id, callback) {
    var deferred = new $.Deferred();
    var branchTimeout = null;

    if (checkForBranch()) {
      var identity = typeof id !== 'string' ? id.toString() : id;

      // start setting the branch identity and wait for it's base callback to run
      branch.setIdentity(identity, function (err) {
        // clear the timeout we set below
        window.clearTimeout(branchTimeout);

        // if we don't have an error, fire our own callback which returns a promise
        if (!err) {
          if ($.isFunction(callback)) {
            callback();
          }
          deferred.resolve();
        } else {
          deferred.reject();
        }

        return deferred.promise();
      });
    }

    // if this hasn't resolved already resolve it after 1s.
    branchTimeout = window.setTimeout(function () {
      deferred.reject();
    }, 1000);

    return deferred.promise();
  };

  /**
   * wrapper for the branch logout method
   * @returns {void}
   */
  api.logout = function () {
    if (checkForBranch()) {
      branch.logout();
    }
  };

  init();

  return api;
})();


SC.analytics.ga = (function () {
  var api = {};

  /**
   init
   Initializaiton function which runs at object instantiation time.
   @returns {void}
   **/
  var init = function () {
    api.bindEvents();
  };

  /**
   @function bindEvents
    Bind events to elements for google analytics event tracking
    ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
    **/

  api.bindEvents = function () {
    /**
     * google analytics event tracking - clicks on bike
     */
    var $bikeSeat = $('.studio_class_select_bike .seat');

    $bikeSeat.click(function () {
      var classId = $('.select-bike-container').attr('data-class-id');
      api.sendEvent('booking', 'bike_clicked', classId );
    });
  };

  api.sendEvent = function (category, action, label, value) {
    if (typeof ga !== 'undefined') {
      if (label && value) {
        ga('send', 'event', category, action, label, value);
      } else if (label) {
        ga('send', 'event', category, action, label);
      } else {
        ga('send', 'event', category, action);
      }
    }
  };

  api.trackConversion = function (id, language, format, color, label, remarketing, value, currency) {
    if (typeof window.google_trackConversion === 'function') {
      window.google_trackConversion({
        google_conversion_id: id,
        google_conversion_language: language,
        google_conversion_format: format,
        google_conversion_color: color,
        google_conversion_label: label,
        google_remarketing_only: remarketing,
        google_conversion_value: value,
        google_conversion_currency: currency
      });
    }
  };

  init();
  return api;
})();

SC.analytics.mp = (function () {
  var api = {};

  /**
    init
    Initializaiton function which runs at object instantiation time.
    @returns {void}
   **/
  var init = function () {

  };

  /**
   * logError: Log a client side error to mParticle.
   *
   * @param {String} errorName The name of the error
   * @param {String} message The details of the error
   * @param {String} stack The stack trace of the error
   * @param {Object|null} _eventData should be in JSON format
   * @returns {void}
   */
  api.logError = function (errorName, message, stack, _eventData) {
    // Set a default value for eventData
    var eventData = _eventData || {};
    var attrs = api.addBaseEventData(eventData);
    mParticle.logError({
      errorName: errorName,
      message: message,
      stack: stack
    }, attrs);
  };

  /**
   * logEvent: Bind events to elements for mparticle analytics event tracking.
   *
   * @param {String} eventName The name of the event
   * @param {String} eventType can be either transaction (ALL ecomm) or navigation (everything else)
   * @param {Object|null} _eventData should be in JSON format
   * @param {String|null} customLabels If label parameter is present, this custom flag allows mparticle to send the label as Event Label to Google Analytics
   * @returns {void}
   */
  api.logEvent = function (eventName, eventType, _eventData, customLabels) {
    // Set a default value for eventData
    var eventData = _eventData || {};

    // Additional location event data
    eventData.request_referrer = document.referrer;
    eventData.request_host = window.location.host;
    eventData.request_pathname = window.location.pathname;
    eventData.request_search = window.location.search;

    try {
      var attrs = api.addBaseEventData(eventData);

      if (eventType === 'navigation') {
        mParticle.logEvent(eventName, mParticle.EventType.Navigation, attrs, customLabels);
        SC.analytics.branch.sendEvent(eventName, { metadata: attrs });
      } else if (eventType === 'transaction') {
        mParticle.logEvent(eventName, mParticle.EventType.Transaction, attrs, customLabels);
      } else if (eventType === 'social') {
        mParticle.logEvent(eventName, mParticle.EventType.Social, attrs, customLabels);
      }
    } catch (e) {
      console.log('There was an error logging the ' + eventName + ' mp event. Error: ' + e.message);
    }
  };


  api.sendEvent = function (eventName, eventType, eventData, label) {
    var customLabel = label ? {'Google.Label': label} : undefined;
    api.logEvent(eventName, eventType, eventData, customLabel);
  };

  api.userEvent = function (eventType, eventData, eventAttribute) {
    try {
      if (eventType === 'setup') {
        var identityRequest = {
          userIdentities: {
            customerid: eventData
          }
        };

        var deferred = new $.Deferred();
        var setupTimeout = null;

        mParticle.Identity.login(identityRequest, function (result) {
          // clear the timeout we set below
          window.clearTimeout(setupTimeout);

          if (Number(result.httpCode) === 200) {
            deferred.resolve();
          } else {
            deferred.reject();
          }
        });

        // if this hasn't resolved already resolve it after 3s.
        setupTimeout = window.setTimeout(function () {
          deferred.reject();
        }, 3000);

        return deferred.promise();
      } else if (eventType === 'attribute') {
        mParticle.Identity.getCurrentUser().setUserAttribute(eventAttribute, eventData);
      } else if (eventType === 'remove') {
        mParticle.Identity.getCurrentUser().removeAllUserAttributes();
      }
    } catch (e) {
      console.log('There was an error sending the mp user event. Error: ' + e.message);
    }

    return true;
  };

  api.setCurrencyCode = function (currencyCode) {
    try {
      mParticle.eCommerce.setCurrencyCode(currencyCode);
    } catch (e) {
      console.log('There was an error setting the mp currency code. Error: ' + e.message);
    }
  };

  api.createProduct = function (name, sku, unitPrice, quantity, category, attrs) {
    var result = null;
    try {
      result = mParticle.eCommerce.createProduct(name, sku, unitPrice, quantity, null, category, null, null, null, attrs);
    } catch (e) {
      console.log('There was an error creating the mp product. Error: ' + e.message);
    }
    return result;
  };

  /**
   * Handles the ViewDetail, AddToCart and RemoveFromCart mParticle events.
   * @param {string} type - String - Which event to call.
   * @param {object} product - object - The product to call the event with.
   * @param {object} _attrs - Custom attributes for mParticle logEvent.
   * @returns {void}
   */
  api.logProductEvent = function (type, product, _attrs) {
    var attrs = _attrs;

    try {
      attrs = api.addBaseEventData(attrs);
      Object.assign(attrs, {'content_type': 'product'});

      mParticle.eCommerce.logProductAction(mParticle.ProductActionType[type], product, attrs);
    } catch (e) {
      console.log('There was an error logging the ' + type + ' mp product event. Error: ' + e.message);
    }
  };

  /**
   * Log a Checkout Step Click. Additionally you can add paymentMethod.
   * When the Checkout Step is submitted, it use the virtual mParticle cart to send along the products.
   * Note: The virtual cart's products will be set after the click of CTA 1.
   *       This is to ensure the cart items are correct.
   * @param {number} step - Step of the Checkout process
   * @param {string} paymentMethod - Type of payment
   * @param {object} _attrs - Custom event attributes
   * @returns {void}
   */
  api.logCheckout = function (step, paymentMethod, _attrs) {
    var attrs = _attrs;

    try {
      attrs = api.addBaseEventData(attrs);
      Object.assign(attrs, {'content_type': 'product'});

      mParticle.eCommerce.logCheckout(step, paymentMethod, attrs);
    } catch (e) {
      console.log('There was an error logging the mp checkout event for Step ' + step + '. Error: ' + e.message);
    }
  };

  /**
   * The updateCart event updates the virtual mParticle cart with the products that are passed in.
   * The logEvent parameter can be used to indicate if an AddToCart event is logged or if it silently updates.
   * @param {array} products -
   * @param {boolean} _logEvent - used to indicate if an AddToCart event is logged or if it silently updates
   * @returns {void}
   */
  api.updateCart = function (products, _logEvent) {
    try {
      var logEvent = _logEvent || false;

      mParticle.eCommerce.Cart.add(products, logEvent);
    } catch (e) {
      console.log('There was an error updating the mp virtual cart. Error: ' + e.message);
    }
  };

  /**
   * This method will clear the mParticle virtual shopping cart of products.
   * Used to update the cart before checkout to ensure the state of the virtual cart and the real one is the same.
   * @returns {void}
   */
  api.clearCart = function () {
    try {
      mParticle.eCommerce.Cart.clear();
    } catch (e) {
      console.log('There was an error clearing mp cart. Error: ' + e.message);
    }
  };

  /**
   * Wrapper method for mparticles createTransactionAttributes method.
   * Creates attributes about the purchase event that are passed to the logPurchase mParticle event.
   *
   * @param {string} id -
   * @param {number} revenue -
   * @param {number} shipping -
   * @param {number} tax -
   * @returns {null|object} -
   */
  api.createTransactionAttributes = function (id, revenue, shipping, tax) {
    var result = null;
    try {
      result = mParticle.eCommerce.createTransactionAttributes(id, null, null, revenue, shipping, tax);
    } catch (e) {
      console.log('There was an error creating the mp transaction events. Error: ' + e.message);
    }
    return result;
  };

  /**
   * Wrapper around the mParticle logPurchase event.
   * Adds base event data then calls the method.
   *
   * @param {object} transactionAttributes - Data about the transaction
   * @param {array} products - Products that are being purchased
   * @param {object} _attrs - Custom event attributes.
   * @returns {void}
   */
  api.logPurchase = function (transactionAttributes, products, _attrs) {
    var attrs = _attrs;

    try {
      attrs = api.addBaseEventData(attrs);
      Object.assign(attrs, {'content_type': 'product'});

      mParticle.eCommerce.logProductAction(mParticle.ProductActionType.Purchase, products, attrs, null, transactionAttributes);
    } catch (e) {
      console.log('There was an error logging the purchase event. Error: ' + e.message);
    }
  };

  /**
   * Method that takes an object of event data and adds more event data about the current window.location.
   * If there is no eventData, it just returns the window.location and document data.
   * @param {object} eventData -
   * @returns {object} {{request_referrer: string, request_host: string, request_pathname: string, request_search: (string|*)}}
   */
  api.addBaseEventData = function addBaseEventData(eventData) {
    var attrs = {
      'request_referrer': document.referrer,
      'request_host': window.location.host,
      'request_pathname': window.location.pathname,
      'request_search': window.location.search,
      'request_platform': SC.utils.getCurrentPlatform()
    };

    if (!eventData) {
      return attrs;
    }

    for (var key in eventData) {
      if (eventData.hasOwnProperty(key)) {
        attrs[key] = eventData[key];
      }
    }

    return attrs;
  };

  /**
   * Get tracked user email attribute set in mParticle
   *
   * @returns {string} email attributes
   */
  api.getEmailAttribute = function getEmailAttribute() {
    if (!mParticle || !mParticle.Identity || !mParticle.Identity.getCurrentUser) {
      return '';
    }

    var user = mParticle.Identity.getCurrentUser();
    if (!user) {
      return '';
    }

    return user.getAllUserAttributes().email || '';
  };

  /**
   * Send CCPA consent details if the user has elected to opt out
   *
   * @param {string} timestamp Unix timestamp of when the action occured. Can be now
   * @param {string} documentVersion legal document agreed to... can be an empty string
   * @param {string} location route or location where the user agreed/action was conducted
   * @return {void}
   */
  api.setupConsent = function setupConsent(timestamp, documentVersion, location) {
    var user = mParticle.Identity.getCurrentUser();
    if (user && !user.getConsentState()) {
      var consentState = window.mParticle.Consent.createConsentState();
      var updatedConsent = window.mParticle.Consent.createCCPAConsent(
        true,
        timestamp,
        documentVersion,
        location
      );
      consentState.setCCPAConsentState(updatedConsent);
      user.setConsentState(consentState);
    }
  };

  init();
  return api;
})();

SC.analytics.tiu = {
  clickAction: function ($button) {
    /**
     * Old GA Send Event - TIU Event.
     * Deprecated: Will be removed soon. Use mParticle to send GA data.
     */
    SC.analytics.ga.sendEvent($button.attr('data-analytics-category'), $button.attr('data-analytics-action'));
  },
  shareAction: function ($button) {
    /**
		 * mParticle event Tracking - social share - TIU
		 */
    var eventData = {
      'Platform': $button.data('platform'),
      'Location': $button.parent().data('location'),
      'Share Type': 'TIU'
    };
    SC.analytics.mp.sendEvent('Share', 'social', eventData, 'TIU');
  }
};
