/**
 * SoulCycle CSRF functionality.
 */

$('form').submit(function () {
  $(this).syncMyCsrfTokenWithPool();
});

// Load up new CSRF tokens if they're available
$(document).ajaxSuccess(function (event, xhr) {
  var newNonces = xhr.getResponseHeader('X-Directus-New-Request-Nonces');
  if (newNonces) {
    newNonces = newNonces.split(',');
    window.soulcycle.noncePool.push.apply(window.soulcycle.noncePool, newNonces);
  }

  // On CSRF failure (e.g. multi-tab/window scenarios), the validator will respond with
  // the full pool, so we can update our cached copy.
  var allNonces = xhr.getResponseHeader('X-Directus-Full-Request-Nonce-Pool');
  if (allNonces) {
    window.soulcycle.noncePool = allNonces.split(',');
    console.log('got all nonces', allNonces);
  }
});

/**
 * Call on a <form /> element.
 * @returns {void}
 */
$.fn.syncMyCsrfTokenWithPool = function () {
  $csrfInput = $(this).find('input[name=csrf_token]');
  if ($csrfInput) {
    $csrfInput.val(window.soulcycle.noncePool.pop());
  }
};

/**
 * Call on a <form /> element.
 * @returns {object} `this` object
 */
$.fn.updateCsrfToken = function () {
  $csrfInput = $(this).find('input[name=csrf_token]');
  if ($csrfInput) {
    $csrfInput.val(window.soulcycle.noncePool.pop());
  }
  return this;
};
$.updateAllCsrfTokens = function () {
  var token = window.soulcycle.noncePool.pop();
  $('input[name=csrf_token]').val(token);
};
