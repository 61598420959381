/* eslint-disable no-unused-vars */

var $dobPlaceholder       = $('.dob_input > #frm-dob_placeholder');
var $dobMonthInput        = $('.global__input-select-dob_group > #frm-dob_month');
var $dobDayInput          = $('.global__input-select-dob_group > #frm-dob_day');
var $dobYearInput         = $('.global__input-select-dob_group > #frm-dob_year');
var $locationPlaceholder  = $('.location_input > #frm-location_placeholder');
var $countryInput         = $('.global__input-select-location_group > #frm-country');
var $stateInput           = $('.global__input-select-location_group > #frm-state');
var $checkboxDiv          = $('#optin_optout_checkbox');
var $provinces            = $('#provinces');
var $selectedCountry      = $('#rider_country');
var $selectedState        = $('#rider_state');
var $dobDayInputSelected  = $('#rider_bd_day');
var $proviceLabel         = $('#state-label');
var $phoneNumberIntl      = $('.phone-number-intl');

var $optSellingContainer  = $('#opt_selling-container');
var $optSellingCheckbox   = $('#opt_selling-checkbox');
var $optSellingLabel      = $('#opt_selling-label');
var $optSellingSpan       = $('#opt_selling-span');

var $optMarketingContainer  = $('#optout_marketing-container');
var $optMarketingCheckbox   = $('#optout_marketing-checkbox');
var $optMarketingLabel      = $('#optout_marketing-label');
var $optMarketingSpan       = $('#optout_marketing-span');

/**
 * Get number of days in a month
 *
 * @param {int} month 0-11
 * @param {int} year 4 digit year (ex. 2020)
 * @returns {int} days in a month (ex Jan or month `1` will return int of 31) -- Note we are using months 1-12 not 0-11
 */
function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

/**
 * Refreshes the date of birth "Day" field <select> options depending on month and year chosen
 * @return {void}
 */
function refreshDobDayInput() {
  var daySelected = $dobDayInputSelected.text() === '' ? $dobDayInput.val() : $dobDayInputSelected.text();
  var selectedValue = parseInt(daySelected, 10);

  $dobDayInput.empty();
  var daysInMonth = getDaysInMonth(parseInt($dobMonthInput.val(), 10), parseInt($dobYearInput.val(), 10));

  for (var z = 1; z <= daysInMonth; z++) {
    var selectedAttr = z === selectedValue ? ' selected' : '';
    $dobDayInput.append('<option value="' + z + '"' + selectedAttr + '>' + z + '</option>');
  }
}

function isOverEighteen() {
  var tempDate = new Date(parseInt($dobYearInput.val(), 10) + 18, parseInt($dobMonthInput.val(), 10) - 1, $dobDayInput.val());
  return (tempDate <= new Date());
}

/**
 * Popuplate State input field with appropriate options according to the Place of Residence field (country field)
 * @return {void}
 */
function loadStatesAndProvinces() {
  try {
    var country = $countryInput.val();
    var provinces = JSON.parse($provinces.text());
    $stateInput.empty();

    if (country === 'GB') {
      $proviceLabel.text('Country');
    } else if  (country === 'CA') {
      $proviceLabel.text('Province');
    } else {
      $proviceLabel.text('State');
    }

    // For UK and Canada, allow user to select empty option
    if (country !== 'US') {
      var emptyOption = document.createElement('option');
      emptyOption.setAttribute('value', '');
      emptyOption.appendChild(document.createTextNode(''));
      $stateInput.append(emptyOption);
    }

    for (var index = 0; index < provinces[country].length; index++) {
      // create option element
      var option = document.createElement('option');
      option.setAttribute('value', provinces[country][index].name);
      option.appendChild(document.createTextNode(provinces[country][index].name));
      $stateInput.append(option);
    }

    var selectedState = $selectedState.text();
    var selectedCountry = $selectedCountry.text();
    if (selectedCountry !== '' && selectedState !== '') {
      $countryInput.val(selectedCountry);
      $stateInput.val(selectedState);
    }
  } catch (err) {
    console.error(err, 'JSON parse error on provinces');
  }
}

/**
 * Toggle checkbox from param on and off
 * @param {String} checkboxName input name
 * @return {void}
 */
function toggleCheckbox(checkboxName) {
  var $checkBox;

  if (checkboxName === 'opt_in_selling') {
    $checkBox = $optSellingCheckbox;
  } else if (checkboxName === 'opt_out_marketing') {
    $checkBox = $optMarketingCheckbox;
  }

  if ($checkBox.val() === '0') {
    $checkBox.val('1');
    $checkBox.prop('checked', 'checked');
  } else {
    $checkBox.val('0');
    $checkBox.removeProp('checked');
  }
}

/**
 * On `/r` referral signup route and `/corporate` signup route add input values.
 * This bypasses the placeholder validation error we need for the main `/signup` route
 *
 * @return {void}
 */
function addPlaceholderInputValues() {
  $dobPlaceholder.val('false');
  $locationPlaceholder.val('false');
}

/**
 * Calculate age based on user input fields
 * @return {int} age of user
 */
function calculateAge() {
  var today = moment();
  var year = $dobYearInput.val();
  var month = $dobMonthInput.val();
  var day = $dobDayInput.val();
  var age = 0;

  if (year.length === 4 && (month >= 1 && month <= 12) && (day >= 1 && day <= 31)) {
    var birthdate = moment(year + '-' + month + '-' + day, 'YYYY-M-D');
    age = today.diff(birthdate, 'years');
  }

  return age;
}

/**
 * This function is used on /r/ (referral) and /corporate
 * Add appropriate attributes to the checkbox field so submission works
 *
 * This needs to be done because normal signup uses step which allows for age checks between.
 * Corp and referral are single page forms that can only check when fields are updated
 *
 *  US logic
 *     if california, check age
 *         age 13-15 -- special opt-in to selling
 *
 *  GB logic
 *     -- special opt in marketing and selling
 *
 * @return {void}
 */
function addOptCheckbox() {
  var checkboxName = false;

  // set defaults
  $checkboxDiv.hide();
  $optSellingContainer.hide();
  $optMarketingContainer.hide();

  $optSellingCheckbox.val('1');
  $optSellingCheckbox.prop('checked', 'checked');

  $optMarketingCheckbox.val('0');
  $optMarketingCheckbox.removeProp('checked');

  if (calculateAge() >= 13 && calculateAge() <= 15 && $stateInput.val() === 'California') {
    checkboxName = 'opt_in_selling';
  } else if ($countryInput.val() === 'GB') {
    checkboxName = 'opt_out_marketing';
  }

  if (checkboxName !== false) {
    $checkboxDiv.css('display', 'block');

    /* eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
    switch (checkboxName) {
      case 'opt_in_selling':
        $optSellingContainer.css('display', 'block');

        $optSellingCheckbox.val('0');
        $optSellingCheckbox.removeProp('checked');
        break;
      case 'opt_out_marketing':
        $optMarketingContainer.css('display', 'block');
        break;
      default:
        console.error('No appropriate option found.');
        break;
    }
  }
}

/**
 * Update the flag for the phone number fields
 *
 * @return {void}
 */
function updateIntlPhoneFlag() {
  var countryCode = window.soulcycle.countryCode;

  if ($phoneNumberIntl.length && countryCode.length > 0) {
    $phoneNumberIntl.intlTelInput('setCountry', countryCode);
  }
}

