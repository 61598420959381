/**
 * shop_category handles the navigation for the shop.
 * It creates a desktop dropdown menu, that becomes an accordion menu on breakpoints < 1024
 * and handles the tab index states for each menu.
 *
 * Rules:
 * Expanding a dropdown/main nav will collapse any previously expanded dropdown.
 * When no dropdown is expanded, the user can tab through the primary menu items.
 * When a dropdown is expanded, focus shifts to the first item in the subnav and users can tab
 * through that active dropdown.
 * Hitting 'enter' on any menu item has the same effect as clicking with the mouse.
 */
SC.shop_category = (function () {
  var api = {};
  var activeListItem = null; // the currently selected list item with an open subnav
  var activeSubMenu = null; // the currently active sub menu
  var container = null;  // main shop nav container
  var containerFixed = false; // indicates if the main container is fixed
  var desktopBP = 1024;
  var desktopNavUpdated = true;
  var header = null; // the shop header that expands the nav on mobile
  var mobileNavUpdated = false;
  var openClass = 'open'; // class indicating open state
  var primaryNav = null; // the primary nav
  var productsContainer = null; // the container for the product grid display
  // state of the tab indexes these get set to -1 when a ul is collapsed
  // and 0 when we expand a menu and want to tab through them
  var tabState = {
    open: '0',
    closed: '-1'
  };

  function init() {
    // Get all elements to act on.
    container = document.getElementById('shop-nav');

    if (!container) {
      return;
    }

    header = container.querySelector('.shop-nav-header');
    primaryNav = container.querySelector('.shop-nav-primary');
    productsContainer = document.querySelector('.shop-retail-products');

    // call handleresize on init to make any adjustments to tab indexes if we're on mobile
    handleResize();
    // then bind all the listeners
    bindListeners();
  }

  /**
   * bindListeners binds all the event listeners needed to toggle the main nav and subnavs.
   * @returns {void}
   */
  function bindListeners() {
    var link = null;
    var subNavs = container.querySelectorAll('.has-subnav');

    // setup scrolling listener
    window.addEventListener('scroll', handleScrolling);
    // resize and orientationchange
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    // Toggle main nav when the shop button is clicked
    header.addEventListener('click', toggleMainNav);
    // Toggle anything that has a subnav when the a tag inside each li is clicked
    // eg. <li><a><ul> -> clicking on 'a' toggles the 'ul'.
    for (var i = 0; i < subNavs.length; i++) {
      link = subNavs[i].getElementsByTagName('a')[0];
      link.addEventListener('click', toggleSubNav);
    }
  }

  /**
   * handleScrolling performs simple updates to fix/unfix the navbar depending
   * on the scrolltop and the authentication. Clicking login on desktop will open
   * the authentication div and requires different fixed positioning than its
   * normal place on the page.  Additionaly it requires adjusting the products container
   * when this occurs.
   * @returns {void}
   */
  function handleScrolling() {
    var authOpen = !!document.querySelector('.auth-open');
    var top = window.pageYOffset;

    if (top > 0 && !containerFixed) {
      if (authOpen) {
        container.classList.add('fixed-secondary-nav');
      } else {
        container.classList.add('fix-retail-nav');
        if (productsContainer) {
          productsContainer.classList.add('fix-retail-products');
        }
      }
      containerFixed = true;
    } else if (top <= 0 && containerFixed) {
      container.classList.remove('fix-retail-nav');
      container.classList.remove('fixed-secondary-nav');
      if (productsContainer) {
        productsContainer.classList.remove('fix-retail-products');
      }
      containerFixed = false;
    }
  }

  /**
   * handleResize check if the window width is less than 1024 and sets the tabIndex to -1
   * for the primary nav links, and to 0 on >= 1024...fires on resize and orientationchange
   * @returns {void}
   */
  function handleResize() {
    if (window.innerWidth < desktopBP) {
      if (!mobileNavUpdated) {
        if (activeListItem) {
          primaryNav.style.height = 'auto';
          primaryNav.setAttribute('aria-hidden', 'false');
          primaryNav.setAttribute('aria-expanded', 'true');
          container.setAttribute('data-expanded', 'true');
          container.classList.add('open');
          updateTabIndexes(primaryNav, tabState.open);
        } else if (!header.classList.contains('open')) {
          primaryNav.setAttribute('aria-hidden', 'true');
          primaryNav.setAttribute('aria-expanded', 'false');
          container.setAttribute('data-expanded', 'false');
          container.classList.remove('open');
          updateTabIndexes(primaryNav, tabState.closed);
        }
        desktopNavUpdated = false;
        mobileNavUpdated = true;
      }
    } else if (!desktopNavUpdated) {
      updateTabIndexes(primaryNav, tabState.open);
      primaryNav.setAttribute('aria-hidden', 'false');
      primaryNav.setAttribute('aria-expanded', 'true');
      container.setAttribute('data-expanded', 'true');
      desktopNavUpdated = true;
      mobileNavUpdated = false;
    }
  }

  /**
   * toggleMainNav handles toggling the main navigation.
   *
   * @param {object} e click event the triggered the function
   * @returns {void}
   */
  function toggleMainNav(e) {
    e.preventDefault();

    this.classList.toggle(openClass);
    toggleElement(primaryNav);
  }

  /**
   * toggleSubNav handles the toggling of the previous active
   * subNav and the toggling of the next active(currently clicked)
   *
   * @param {object} e the click event that triggered this subnav
   * @returns {void}
   */
  function toggleSubNav(e) {
    // prevent the default behavior on the link
    e.preventDefault();

    // cache the next sibling of the current link
    var list = this.nextElementSibling;

    // check if we have an active subnav and link. if so remove classes and toggle it
    if (activeListItem && activeListItem !== this.parentElement) {
      activeListItem.classList.toggle(openClass);
      toggleElement(activeSubMenu);
    }

    // get this subnav's parent list and toggle the open class (used to transform the arrow up/down)
    this.parentElement.classList.toggle(openClass);

    // toggle the ul element
    toggleElement(list);
  }

  /**
   * toggleElement handles routing to collapseElement or expandElement depending on
   * the aria-expanded attribute
   *
   * @param {object} node html element node to toggle
   * @returns {void}
   */
  function toggleElement(node) {
    var expanded = node.getAttribute('aria-expanded');

    if (expanded === 'true') {
      collapseElement(node);
    } else {
      expandElement(node);
    }
  }

  /**
   * collapseElement handles collapsing the node passed in as an argument.  it also
   * collapses any previously active element that was set.
   *
   * @param {object} node html element node to collapse
   * @returns {void}
   */
  function collapseElement(node) {
    var height = node.scrollHeight;
    var navTransition = node.style.transition;

    // start updating the tab indexes for the links in this node so that
    // screenreaders can skip when the node is collapsed.
    updateTabIndexes(node, tabState.closed);

    // temporarily disable the transition so it doesn't get applied during the
    // first frame of animation.
    node.style.transition = '';

    // on the first frame set the height and then reset the transition
    window.requestAnimationFrame(function () {
      node.style.height = height + 'px';
      node.style.transition = navTransition;

      // on the immediate frame after remove the inline height style and allow the
      // height set through css to dictate what to collapse to.
      window.requestAnimationFrame(function () {
        node.style.height = null;
      });
    });

    // if this is a menubar and we have an active list item go ahead and deactivate.
    if (node.getAttribute('role') === 'menubar') {
      container.setAttribute('data-expanded', 'false');
      container.classList.remove('open');

      if (activeListItem) {
        activeListItem.classList.toggle(openClass);
        collapseElement(activeSubMenu);
        activeListItem = null;
        activeSubMenu = null;
      }
    }

    // reset the active sub nav to null if this is the current active sub nav
    if (activeListItem && activeListItem === node.parentElement) {
      activeListItem = null;
    }

    if (productsContainer) {
      productsContainer.classList.remove('fix-retail-produts');
    }

    node.setAttribute('aria-expanded', 'false');
    node.setAttribute('aria-hidden', 'true');
  }

  /**
   * expandElement handles expanding the node passed in as an argument.  it also
   * set the active list item and subnav
   *
   * @param {object} node html element node to expand
   * @returns {void}
   */
  function expandElement(node) {
    var height = node.scrollHeight;

    updateTabIndexes(node, tabState.open);
    node.classList.add(openClass);
    node.style.height = height + 'px';

    // we set the height above and then when the transitionend event fires
    // we remove the listener so that it doesn't fire again and then set the inline
    // style of the height to auto.
    node.addEventListener('transitionend', function removeListenerAndResetStyle() {
      node.removeEventListener('transitionend', removeListenerAndResetStyle);

      node.style.height = 'auto';
    });

    // set the aria attributes on the current node
    node.setAttribute('aria-expanded', 'true');
    node.setAttribute('aria-hidden', 'false');
    container.setAttribute('data-expanded', 'true');
    container.classList.add('open');

    // if we're dealing with a subnav set the active list item and active submenu.
    if (node !== primaryNav) {
      // set the parent element('li') as the active subnav
      activeListItem = node.parentElement;
      // set the active link to the current node that triggered
      // the event.
      activeSubMenu = node;
    }
  }

  /**
   * updateTabIndexes updates all tab indexes when an element is expanded or collapsed.
   * If a subnav is expanded all menuitems contained within will have their tabIndex set to "0".
   * Likewise if collapsed they will be set to "-1".
   *
   * @param {object} node html element node to update all tab indexes for
   * @param {string} state a string that indicates if tabbed whether the node should be active
   * @returns {void}
   */
  function updateTabIndexes(node, state) {
    if (!node) return;

    // Check if we have an id for this node.  We need this to use in the querySelectorAll to target only direct descendants
    node.id = node.id || '_' + Math.random().toString(16);

    // get each menuItem in this list (top level only)
    var menuItems = node.querySelectorAll('#' + node.id + '> li > [role="menuitem"]');

    // activate/deactivate the tabIndexes depending on the state passed in as an argument.
    for (var i = 0; i < menuItems.length; i++) {
      menuItems[i].setAttribute('tabIndex', state);
    }

    // if this is an expansion set the focus on the first menu item
    if (state === '0') {
      menuItems[0].focus();
    }
  }

  init();
  return api;
})();
