/**
 * The class that binds and handles the authentication overlay block
 *
 * @param {object} $element - element
 * @returns {void}
 */
// TODO: is clear that we are using this function globally in other places how we can avoid this rule for this specific case
// eslint-disable-next-line no-unused-vars
function AuthenticationOverlay($element) {
  var self = this;

  this.$element         = $element;
  this.$close           = this.$element.find('.authentication-close');
  this.$skip_button     = this.$element.find('button.skip');
  this.$login_button    = this.$element.find('button.login');
  this.$login_header    = this.$element.find('#login-header');
  this.$signup_header   = this.$element.find('#signup-header');
  this.$sub_header      = this.$element.find('#sub-header');
  this.$terms_message   = this.$element.find('#registration-terms-message');
  this.$register_button = this.$element.find('#register-button');
  this.sections         = {};
  this.callback         = false;
  this.activeSection    = '';

  this.show = function (_section, callback, _options) {
    var options = _options || {};
    var section = _section || 'login';

    this.setOptions(options);
    this.showSection(section);
    this.$element.show();
    this.callback = callback;
  };

  this.hide = function () {
    this.$element.hide();
    this.callback = false;
  };

  /**
     * Summary: Function to handle the options sent from `show` function
     * @param {object} options - options
     * @return {void}
     */
  this.setOptions = function (options) {
    var loginHeaderTxt = options.header_text || 'Sign In';
    var signupHeaderTxt = options.signup_header_text || 'Create Account';
    var subHeaderTxt = options.sub_header_text || '';
    var overrideLoginTxt = options.override_login_text || 'login';
    var registerButtonTxt = options.register_button_text || 'finish up';
    var termsMessage = options.terms_message || 'Please click "' + registerButtonTxt + '" to accept our Terms and Conditions.';
    var showSkipButton = options.show_skip_button || false;

    if (showSkipButton) {
      self.$skip_button.removeClass('hide');
    } else {
      self.$skip_button.addClass('hide');
    }

    self.$login_header.html(loginHeaderTxt);
    self.$signup_header.html(signupHeaderTxt);
    self.$sub_header.html(subHeaderTxt);
    self.$login_button.html(overrideLoginTxt);
    self.$terms_message.html(termsMessage);
    self.$register_button.html(registerButtonTxt);
  };

  this.defaulCallback = function () {
    location.reload();
  };

  // Function to switch between authentication sections
  this.showSection = function (section) {
    this.activeSection = section;
    for (var authSection in this.sections) {
      if (authSection === section) {
        this.sections[authSection].show();
        var $firstInput = this.sections[authSection].find('form:first input:first');
        $firstInput.focus();
      } else {
        this.sections[authSection].hide();
      }
    }

    if (section === 'registration') {
      this.getTerms();
    }
  };

  // Function to display error
  this.displayFormErrors = function ($form, errors) {
    var $formError = $form.find('.form-error-message');
    var $formErrorList = $formError.find('.form-error-list').html('');
    var errorMessage = '';

    if ($.isPlainObject(errors)) {
      for (var error in errors) {
        if (errors.hasOwnProperty(error)) {
          var $input = $form.find('input[name=' + error + ']');

          if ($input.length > 0) {
            var $fieldContainer = $input.parents('.field-container');
            $fieldContainer.addClass('field-error');
            $fieldContainer.find('.field-error-message').html(errors[error]);

            // If there isn't a error message element, create one
            var $messageElm = $fieldContainer.find('.field-error-message');
            if ($messageElm.length === 0) {
              $messageElm = $('<div class="field-error-message"></div>');
              $fieldContainer.append($messageElm);
            }
            $messageElm.html(errors[error]);

            errorMessage += '<li>' + errors[error] + '</li>';
          }
        }
      }
    } else {
      errorMessage = '<li>' + errors + '</li>';
    }


    $formErrorList.html(errorMessage);
    $formError.show();
    $formError.focus();
  };

  this.clearFormError = function ($form) {
    $form.find('form-error-message').hide();
  };

  // Basic validation
  this.validate = function ($form) {
    var errors = {};

    // Check for required fields
    $form.find(':required').each(function () {
      if (this.value === '') {
        var $label = $(this).parents('.field-container').find('label');
        errors[this.name] = 'The ' + $label.text() + ' field is required';
      }
    });

    return errors;
  };

  // Process Form
  this.submitForm = function ($form) {
    this.clearFormError($form);

    var errors = self.validate($form);
    if (!$.isEmptyObject(errors)) {
      this.displayFormErrors($form, errors);
      return;
    }

    var formDate = new FormData($form[0]);
    $.ajax({
      url: $form.attr('action'),
      type: 'post',
      processData: false,
      contentType: false,
      dataType: 'json',
      data: formDate,
      success: function (data) {
        if (data.success) {
          if (typeof self.callback === 'function') {
            self.callback();
          } else {
            self.defaulCallback();
          }
        } else {
          errors = data.fieldErrors || data.message;
          self.displayFormErrors($form, errors);
        }
      },
      error: function () {
        self.displayFormErrors($form, {'unknown': 'An unknown error has occurred'});
      }
    });
  };

  this.getTerms = function () {
    var $registrationForm  = this.$element.find('.authentication-registration-form');

    $.ajax({
      url: '/terms/',
      dataType: 'json',
      success: function (data) {
        if (data.success) {
          var $agreementInput = $registrationForm.find('input[name=agreement_id]');
          var $agreementText = $registrationForm.find('.form-terms');
          $agreementInput[0].value = data.agreement_id;
          $agreementText.html(data.content);
        } else {
          self.displayFormErrors($registrationForm, {'unknown': 'An unknown error has occurred'});
        }
      },
      error: function () {
        self.displayFormErrors($registrationForm, {'unknown': 'An unknown error has occurred'});
      }
    });
  };

  // Initialization
  this.init = function () {
    // Overlay event to hide dialog
    this.$element.click(function (e) {
      // Prevent this event from firing on child elements
      if (e.target === self.$element[0]) {
        self.hide();
      }
    });

    this.$close.click(function () {
      self.hide();
    });

    this.$skip_button.click(function (e) {
      e.preventDefault();
      self.hide();
    });

    // Set event for the login form submit event
    this.$element.find('form').submit(function (e) {
      e.preventDefault();
      self.submitForm($(this));
    });

    this.$element.find('.authentication-link').click(function () {
      var section = $(this).attr('section');
      self.showSection(section);
    });

    this.$element.find('.authentication-section').each(function () {
      var section = $(this).attr('section');
      self.sections[section] = $(this);
    });

    // Set events for all inputs
    var $inputs = this.$element.find('input');
    $inputs.blur(function (e) {
      var $fieldContainer = $(e.target).parents('.field-container');
      if (e.target.value !== '') {
        $fieldContainer.addClass('filled');
        $fieldContainer.removeClass('field-error');
      } else {
        $fieldContainer.removeClass('filled');
      }
      $fieldContainer.removeClass('focused');
    });

    $inputs.focus(function (e) {
      var $fieldContainer = $(e.target).parents('.field-container');
      $fieldContainer.addClass('focused');
    });
  };

  this.init();
}

