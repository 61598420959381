/* global AuthenticationOverlay __adroll:true */

'use strict';
var SC = SC || {};
if (!window.soulcycle) {window.soulcycle = {};}

var genericErrorMessage = 'Uh oh. Somethings not right. Please try that action again.';

function headerSC() {
  var $header = $('header');
  var $body = $('body');
  var $soulContainer = $('.soul-container');
  var $whiteOverlay = $('.white-overlay');

  // SOULBURGER MENU
  $header.on('click', '.mobile-nav-icon', function () {
    $('.white-overlay-opaque').css('opacity', '1');
    $('.white-overlay-opaque').slideDown(100);
    $('body').addClass('overflow-hidden');
  });

  $header.on('click', '.mobile-nav-close', function () {
    $('.white-overlay-opaque').slideUp(100);
    $('body').removeClass('overflow-hidden');
  });

  /**
   * Old GA sendEvent - checkout step - go to shopping bag
   * Deprecated: Will be removed soon.
   */
  $header.on('click', '.shop-counter', function () {
    SC.analytics.ga.sendEvent('shop', 'checkout', 'shopping_bag_page');
  });

  var handleHeaderNavAnalytics = function handleHeaderNavAnalytics(e) {
    var $clickeElement = $(e.target);
    var clickedNavItemText = $clickeElement.text();
    var eventName = '';

    switch (clickedNavItemText) {
    case 'about':
      eventName = 'Click About';
      break;
    case 'studios':
      eventName = 'Click Studios';
      break;
    case 'instructors':
      eventName = 'Click Instructors';
      break;
    case 'community':
      eventName = 'Click Community';
      break;
    case 'turn it up':
      eventName = 'Click Turn It Up';
      break;
    case 'shop':
      eventName = 'Click Shop';
      break;
    default:
      if ($clickeElement.parents('.shop-counter')) {
        eventName = 'Click Shop Bag';
      }
      break;
    }

    // Send mParticle events of the navigations
    SC.analytics.mp.sendEvent(eventName, 'navigation', { 'Location': 'Navigation' }, 'Navigation');
  };

  $('header .header-nav').on('click', 'ul a', handleHeaderNavAnalytics);

  // FIND/BUY CLASS DROPDOWN
  var $findBuyButton = $('.find-a-class-btn');
  var $findBuyDropdown = $('.find-a-class');
  var $findBuyDropdownContainer = $('.find-a-class');
  var $findBuyClose = $('.buy-series-close-button');
  var $buySeriesBtns = $('.buy-series-btn');
  var $homepage = $('.homepage .main-container, .homepage .homepage-container');
  var $userDropDown = $('.authenticated-user__dropdown');
  var $userNameText = $('.authenticated-username');
  var navRegionBtn = document.getElementById('nav-region-btn');
  var navFlagBtn = document.getElementById('nav-region-flag-btn');

  $findBuyButton.on('mouseenter', function () {
    $(this).trigger('click');
  });

  $header.on('mouseenter', '.header-user-name-block', function () {
    $whiteOverlay.removeClass('opaque');
    $findBuyDropdown.removeClass('touch_hover');
    $body.removeClass('overflow-hidden');
    $homepage.removeClass('homepage-height-fix');
    navRegionBtn.setAttribute('aria-expanded', 'false');
    navFlagBtn.setAttribute('aria-expanded', 'false');
    $findBuyButton.attr('aria-expanded', 'true');
  });

  $header.on('mouseenter', '.header-user-name-block', function () {
    $userDropDown.addClass('active');
    $userNameText.addClass('active');
  });

  $header.on('mouseleave', '.header-user-name-block', function () {
    $userDropDown.removeClass('active');
    $userNameText.removeClass('active');
  });

  $header.on('keydown', '.authenticated-username__link', function (e) {
    // Space and enter should be the only keys that allow a click like behavior
    // For any other key do nothing
    if (e.key === 'Enter' ||  e.key === ' ') {
      e.preventDefault();

      $userDropDown.toggleClass('active');
      $userNameText.toggleClass('active');
    }
  });

  $findBuyDropdownContainer.bind({
    mouseleave: function () {
      // hide find/buy dropdown
      $findBuyDropdown.removeClass('touch_hover');
      $body.removeClass('overflow-hidden');
      $homepage.removeClass('homepage-height-fix');
      $whiteOverlay.removeClass('opaque');
      navRegionBtn.setAttribute('aria-expanded', 'false');
      navFlagBtn.setAttribute('aria-expanded', 'false');
      $findBuyButton.attr('aria-expanded', 'false');
    }
  });

  $header.on('click', '.find-a-class-btn', function () {
    $findBuyDropdown.toggleClass('touch_hover');
    $homepage.toggleClass('homepage-height-fix');
    $whiteOverlay.toggleClass('opaque');

    if ($findBuyDropdown.hasClass('touch_hover')) {
      navRegionBtn.setAttribute('aria-expanded', 'true');
      navFlagBtn.setAttribute('aria-expanded', 'true');
      $findBuyButton.attr('aria-expanded', 'true');
    } else {
      navRegionBtn.setAttribute('aria-expanded', 'false');
      navFlagBtn.setAttribute('aria-expanded', 'false');
      $findBuyButton.attr('aria-expanded', 'false');
    }
  });

  $findBuyButton.on('keydown', function (e) {
    if ((e.key === 'Enter' || e.key === ' ') && !$findBuyDropdown.hasClass('touch_hover')) {
      e.preventDefault();
      $(this).trigger('click');
      // This is to wait until the element is visible
      setTimeout(function () {
        $('.change-region-link').focus();
      }, 100);
    }
  });

  $findBuyClose.on('click', function () {
    $findBuyDropdown.removeClass('touch_hover');
    $body.removeClass('overflow-hidden');
    $homepage.removeClass('homepage-height-fix');
    $whiteOverlay.removeClass('opaque');
    navRegionBtn.setAttribute('aria-expanded', 'false');
    navFlagBtn.setAttribute('aria-expanded', 'false');
  });
  $whiteOverlay.on('click', function () {
    $findBuyDropdown.removeClass('touch_hover');
    $body.removeClass('overflow-hidden');
    $homepage.removeClass('homepage-height-fix');
    $(this).removeClass('opaque');
    navRegionBtn.setAttribute('aria-expanded', 'false');
    navFlagBtn.setAttribute('aria-expanded', 'false');
  });

  // Send mparticle data for a buy series button click
  $buySeriesBtns.on('click', function () {
    SC.analytics.mp.sendEvent('Click Buy Classes', 'navigation', { 'Location': 'Navigation' }, 'Navigation');
  });

  $soulContainer.on('click', '.close-banner-btn', function () {
    $('.vegas-banner-container').slideUp();
  });

  // Check if there is purchase_info on the soulcycle object.
  // If there is, it means the rider was redirected to a different page after checking out.
  // This means we need to log the mparticle event since it did not happen already.
  window.mParticle.ready(function () {
    if (window.soulcycle.purchase_info) {
      logNewRiderPurchaseEvent();
    }
  });
}

headerSC();

function subHeaderMenus() {
  var changeRegionBtn = document.getElementById('region_button');
  var regionList = document.getElementsByClassName('region-dropdown-list')[0];
  var regionContainer = document.getElementsByClassName('region-dropdown')[0];

  if (changeRegionBtn !== null) {
    var regionBtnEvent = function (e) {
      e.stopPropagation();
      e.preventDefault();

      if (!$('.soul-container').hasClass('auth-open')) {
        $('header').toggleClass('header-position');
        $('body').scrollTop(0);
      }

      if (regionList.classList.contains('block')) {
        regionList.classList.remove('block');
        $('.instructors-header-options, .studios-header-options, .region-dropdown').removeClass('full-height');
        regionList.setAttribute('aria-hidden', 'true');
        changeRegionBtn.setAttribute('aria-expanded', 'false');
      } else {
        regionList.classList.add('block');
        // this toggled class allows the menu to be scrollable for mobile
        $('.instructors-header-options, .studios-header-options, .region-dropdown').addClass('full-height');
        regionList.removeAttribute('aria-hidden');
        changeRegionBtn.setAttribute('aria-expanded', 'true');

        /**
         * Old google analytics sendEvent - studios region dropdown click
         * Deprecated: Will be removed soon.
         */
        SC.analytics.ga.sendEvent('studios', 'region_dropdown_click');

        /**
         * mParticle sendEvent - Click Browsing Regions - Studio and Instructor Selection
         * Sends an mParticle event with a custom label for GA.
         */
        var location = changeRegionBtn.getAttribute('data-location');
        SC.analytics.mp.sendEvent(
          'Click Browsing Regions',
          'navigation',
          {
            'Location': location
          },
          location
        );

        setTimeout(function () {
          regionList.getElementsByTagName('a')[1].focus();
        }, 0);
      }
    };

    changeRegionBtn.addEventListener('click', regionBtnEvent);

    regionList.addEventListener('focusout', function (e) {
      if (regionContainer.contains(e.relatedTarget)) {
        return;
      }

      regionList.classList.remove('block');
      changeRegionBtn.setAttribute('aria-expanded', 'false');
      regionList.setAttribute('aria-hidden', 'true');
    });

    $('html').click(function () {
      regionList.classList.remove('block');
      $('.instructors-header-options, .studios-header-options, .region-dropdown').removeClass('full-height');
      regionList.setAttribute('aria-hidden', 'true');
      changeRegionBtn.setAttribute('aria-expanded', 'false');
    });
  }
}

subHeaderMenus();

function toggleThrobber(_enabled) {
  var $whiteOverlay = $('.white-overlay');

  var enabled = _enabled;
  if (typeof enabled === 'undefined') {
    enabled = true;
  }

  if (enabled) {
    $whiteOverlay.stop().animate({
      opacity: 1
    }, 200).show();
    $('html').css({'cursor': 'wait'});
    return;
  }
  $whiteOverlay.stop().animate({
    opacity: 0
  }, 200, function () {
    $(this).hide();
  });
  $('html').css({'cursor': 'default'});
}


/**
 * Function that logs an mParticle and Branch purchase event after a redirect after checkout.
 * The purchase event fires on the next page after checkout to ensure we are not tracking purchases with errors.
 *
 * The data for the purchase event is added to the session, then to a twig template in a javascript object.
 * This function checks if its present, then logs the purchase event if it is.
 *
 * @returns {void}
 */
function logNewRiderPurchaseEvent() {
  try {
    // If there is no purchase data, just return and don't log the event.
    if (!window.soulcycle.purchase_info) {
      return;
    }

    var data = window.soulcycle.purchase_info;
    var transactionAttributes = SC.analytics.mp.createTransactionAttributes(
      data.orderId,
      data.revenue,
      data.shipping,
      data.tax
    );
    var products = [];

    SC.analytics.mp.setCurrencyCode(data.currencyCode);
    SC.analytics.branch.currencyCode = data.currencyCode;

    // Set the name and sku differently depending if its series or merchandise to match app mparticle data.
    for (var key in data.items) {
      if (data.items.hasOwnProperty(key)) {
        var item = data.items[key];
        var type = (String(item.tax_category_id) === '3') ? 'Series' : 'Merchandise';
        var productName = SC.utils.getProductTitle(type, item.size, item.title);
        var baseSku = SC.utils.getBaseSku(type, item.sku, item.slug);
        var sku = SC.utils.getSku(type, item.sku, item.size);
        var attrs = {'Base Sku': baseSku};
        var product = SC.analytics.mp.createProduct(
          productName,
          sku,
          item.price,
          item.product_quantity,
          null,
          attrs
        );

        products.push(product);
      }
    }

    // mParticle logPurchase Event
    SC.analytics.mp.logPurchase(
      transactionAttributes,
      products,
      {
        'Location': 'Shop',
        'Step': 4
      }
    );

    /**
     * Branch send Event - Purchase
     */
    SC.analytics.branch.trackCommerceEvent(transactionAttributes, products);
  } catch (e) {
    console.error('There was an error trying to log the purchase event. Error: ' + e.message);
    SC.analytics.mp.logError('Purchase tracking error', e.message, '');
    SC.analytics.mp.clearCart();
  }
}

$(function () {
  // In the interest of time, this only works on pageload.
  // If we want to get fancier with hashchanges, e.g. after use pageload, we'll want to use the hashchange library.
  if (window.location.hash === '#register') {
    window.location = '/signin/';
  }

  /**
     Change Region Tooltip - using utility api.changeRegion fucntion
     **/
  $('.change-region-tooltip a').click(function (e) {
    e.preventDefault();

    // data passed to SC.utils.changeRegion function
    var updateRegion = $(this).data('id');
    // global function which loads updated region
    SC.utils.changeRegion(updateRegion, false, SC.utils.updateBubbleIndicatorClass);
  });

  // My Account > My Info
  // Checkout > Payment
  // Checkout > Shipping Info
  // appends states/provinces options given the selected country
  function registerCountryDropdownEvents() {
    $('select.country-options').on('change', function () {
      var countryAbbr = $(this).val();
      var formSectionType = $(this).data('section-type');
      formSectionType = formSectionType ? formSectionType + '_' : '';
      var stateOptions = soulcycle.addressStateOptions[countryAbbr];
      var zip = document.getElementById('frm-' + formSectionType + 'zip');

      // remove old options
      document.getElementById('frm-' + formSectionType + 'state').innerHTML = '';
      // replace with new options
      $.each(stateOptions, function (idx, state) {
        $('<option value="' + state + '">' + state + '</option>').appendTo($('select#frm-' + formSectionType + 'state'));
      });

      // update zip/postal code
      zip.nextElementSibling.innerHTML = countryAbbr === 'US' ? 'Zip' : 'Postal Code';
    });
  }

  function registerBagEvents() {
    setTimeout(function () {
      $('.shipping-method-form select').on('change', function () {
        var $form = $(this).closest('form');
        $form.submit();
      });
      $('.shopping-bag-grid-container').find('.item-quantity select').on('change', function () {
        if (!$(this).closest('.bag-item-row').hasClass('gift-card')) {
          var $form = $(this).closest('form');
          $form.submit();
        }
      });
    }, 100);

    $('.bag-item-row .item-remove').click(function () {
      var genericBagRemoveErrorMessage = 'WE EXPERIENCED AN ERROR REMOVING THIS ITEM FROM YOUR BAG. TRY THAT AGAIN!';
      var $item = $(this).closest('.bag-item-row');
      var itemId = $item.data('id');
      $item.fadeOut('fast');

      $.ajax({
        url: '/shop/remove-from-bag/',
        dataType: 'json',
        data: {
          item: itemId,
          csrf_token: window.soulcycle.noncePool.pop()
        },
        method: 'POST',
        success: function (data) {
          if (data.success) {
            $item.remove();
            confirmation(undefined, "We've removed that item from your bag.");
            return;
          }
          confirmation(undefined, genericBagRemoveErrorMessage);
          $item.show('fast');
        },
        error: function () {
          confirmation(undefined, genericBagRemoveErrorMessage);
          $item.show('fast');
        }
      });
    });
  }

  /**
     * Class Action Buttons
     * --------------------
     * These occur on multiple pages:
     *  - Find a Class
     *  - Instructor Detail
     */
  $('a.supersoul').click(function (e) {
    e.preventDefault();
    var buttons = false;
    var modalText = '';
    var link = false;
    if (parseInt(window.soulcycle.rider.hasSupersoul, 0)) {
      modalText = 'please email <a target="_blank" href="mailto:supersoul@soul-cycle.com" rel="noopener noreferrer">supersoul@soul-cycle.com</a> to reserve this class';
    } else {
      var classDay = $(e.target).parents('.column-day').data('date');
      var weekStartDay = $('.week-info').data('week-start-day');
      // monday is the start of the sc week, first monday classes open up the PREVIOUS week.
      var weekStartDate = $('.week-info').data('last-week-start-date');


      if (classDay !== weekStartDay) {
        weekStartDate = $('.week-info').data('week-start-date');
      }

      if (weekStartDate === undefined || !weekStartDate) {
        modalText = 'Classes do not open for reservations until the Monday prior to the class.';
      } else {
        modalText = 'This class opens for reservations on ' + weekStartDate + " at noon... <span class='visually-hidden'>Use your browser's modifier key plus 'k' to bookmark the class.</span>";
      }

      var classId = $(e.currentTarget).closest('.session').data('class-id');
      var cb = function () {
        console.debug('cb', classId);
        $('[data-class-id="' + classId + '"] .bookmark').trigger('click');
        return false;
      };
      buttons = {'bookmark': { label: 'bookmark', callback: cb}};
    }
    confirmation(link, modalText, buttons, 10);
  });

  $('a.reserve').click(function (e) {
    e.stopPropagation();
    var $target = $(e.target);

    /**
     * mparticle sendEvent - Click Reserve Button
     * Sends an mparticle event and a label for GA event.
     *
     * GA Event Info:
     * Category = Navigation
     * Action: Click Reserve Button
     * Label: location [Instructor/Studio Layout/Schedule]
     */
    var classData = $target.data();
    var eventData = {
      'Class Time': classData.classTime,
      'Class Type': classData.classType,
      'Instructor Nickname': classData.instructor,
      'Instructor ID': classData.instructorId,
      'Class ID': classData.classId,
      'Studio Name': classData.studio,
      'Location': classData.location
    };

    SC.analytics.mp.sendEvent('Click Reserve Button', 'navigation', eventData, classData.location);
  });

  $('a.waitlist').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    var $target = $(e.target);
    var soulcycle = window.soulcycle;
    if (soulcycle.rider.id) {
      toggleThrobber(true);
      var $class = $(this).closest('.session');
      var classId = $class.data('class-id');

      $.ajax({
        url: '/find-a-class/join-waitlist/',
        method: 'POST',
        dataType: 'json',
        data: {
          'class_id': classId,
          'csrf_token': window.soulcycle.noncePool.pop()
        },
        success: function (data) {
          toggleThrobber(false);
          if (data.success) {
            confirmation('me_classes', "YOU'VE SUCCESSFULLY JOINED THE WAITLIST FOR THIS CLASS!");

            /**
             * Old GA send Event - waitlist button
             * Deprecated: Will be removed soon.
             *
             * category = page
             * action = waitlisted
             * label = class id
             */
            SC.analytics.ga.sendEvent($target.data('analytics-category'), $target.data('analytics-action'), $target.data('analytics-label').toString());

            /**
             * mParticle analytics event tracking - waitlist button
             * eventName = Add to Waitlist
             * eventCategory = Navigation
             */
            var classData = $target.data();
            var eventData = {
              'Class ID': classData.analyticsLabel,
              'Location': classData.location,
              'Instructor Nickname': classData.instructor,
              'Instructor ID': classData.instructorId,
              'Studio Name': classData.studio,
              'Class Type': classData.classType,
              'Class Time': classData.classTime
            };
            SC.analytics.mp.sendEvent('Taps Add to Waitlist', 'navigation', eventData, classData.location);

            SC.utils.ui.incrementOrDecrementSeriesMenu(data, 'decrement');

            return;
          }
          var message = data.message ? data.message : genericErrorMessage;
          var linkRouteName;
          switch (data.code) {
          case 'insufficientseries':
            linkRouteName = 'series_purchase';
            break;
          // No Default
          }
          confirmation(linkRouteName, message);
        },
        error: function () {
          toggleThrobber(false);
          confirmation(undefined, genericErrorMessage);
        }
      });
      return;
    }
    var modalText = 'YOU MUST BE LOGGED IN TO JOIN A CLASS WAITLIST.';
    confirmation(undefined, modalText);
  });

  // Bookmark
  //   Occurs on Find a Class
  //   and Bookmarks page
  var $bookmark = $('body.logged-in .session .bookmark');

  $bookmark.click(function (e) {
    if (e.type === 'keydown' && e.key !== 'Enter' && e.key !== ' ') {
      return;
    }

    var $button = $(this);
    var $class = $button.closest('.session');
    var $target = $(e.target);
    var bookmarkAction = $button.hasClass('active') ? 'Bookmark' : 'Unbookmark';
    var bookmarkSource = $button.hasClass('active') ? '/assets/images/bookmark_outline.svg' : '/assets/images/bookmark_yellow.svg';
    var bookmarkAlt = $button.hasClass('active') ? 'Toogle bookmark - Not bookmarked' : 'Toogle bookmark - Bookmarked';
    var classData = $button.data();
    var previousBookmarkSource = $button.attr('src');
    var previousBookmarkTitle = $button.attr('title');
    var targetState = !$button.hasClass('active') ? 1 : 0;

    var $classTime = moment(classData.classTime);
    var bookmarkTitle = bookmarkAction + ' ' + classData.classType +
                            ' class with '  + classData.instructorNickname + ' on ' +
                            $classTime.format('MMMM Do YYYY') + ' at ' + $classTime.format('h:mmA');

    $button.toggleClass('active')
      .attr({
        'src': bookmarkSource,
        'title': bookmarkTitle,
        'alt': bookmarkAlt,
        'aria-selected': $button.hasClass('active')
      });

    $class.toggleClass('bookmarked');

    var $form = $button.closest('form');
    $form.ajaxSubmit({
      dataType: 'json',
      data: {
        class_id: $class.data('class-id'),
        target_state: targetState,
        csrf_token: window.soulcycle.noncePool.pop()
      },
      success: function (data) {
        $form.updateCsrfToken();
        /**
                     * mparticle analytics event tracking - taps to bookmark/unbookmark
                    */
        var eventData = {
          'Location': classData.label,
          'Instructor Nickname': classData.instructorNickname,
          'Instructor ID': classData.instructorId,
          'Studio Name': classData.studioName,
          'Class Type': classData.classType,
          'Class Time': classData.classTime,
          'Class ID': classData.analyticsLabel
        };
        SC.analytics.mp.sendEvent(!targetState ? 'Click to Unbookmark Class' : 'Click to Bookmark Class', 'navigation', eventData, classData.label);

        if (!data.success) {
          $button.toggleClass('active')
            .attr({
              'src': previousBookmarkSource,
              'title': previousBookmarkTitle,
              'aria-selected': $button.hasClass('active')
            });
          $class.toggleClass('bookmarked');
          confirmation(undefined, genericErrorMessage);
          return;
        }

        // These classes indicate that we want to hide the banner that prompted the bookmark and show a successful bookmark
        var bookmarkPages = '.studio_find_class, .instructor_schedule';

        if ($('body').is(bookmarkPages) && $button.hasClass('active')) {
          $('body').addClass('bookmarked-class');
          confirmation(undefined, 'Great! Added to bookmarks.');
        }

        /**
         * Old GA sendEvent - active bookmarked class
         * Deprecated: Will be removed shortly.
         *
         * action = 'bookmarked'
         * label = classId
         */
        if ($button.hasClass('active')) {
          SC.analytics.ga.sendEvent($target.data('analytics-category'), $target.data('analytics-action'), $target.data('analytics-label').toString() );
        }
      },
      error: function () {
        $form.updateCsrfToken();
        $button.toggleClass('active');
        $class.toggleClass('bookmarked');
        confirmation(undefined, genericErrorMessage);
      }
    });
  });

  $bookmark.keydown(function (e) {
    if (e.key === 'Enter') {
      $(this).trigger('click');
    }
  });

  /**
   * End Class Action Buttons
   * @returns {void}
   */
  SC.checkout = function () {
    registerBagEvents();
    registerCountryDropdownEvents();

    $('.giftcard-container .submit').click(function (e) {
      e.preventDefault();
      var $form = $(this).closest('form');
      $form.submit();
    });

    $('.checkout-section .form-button.save').click(function (e) {
      e.preventDefault();
      var $form = $(this).closest('form');
      $form.submit();
    });

    $('form#checkout').on('click', 'a.place-order.disabled', function (e) {
      e.preventDefault(); // Prevent hashchange
    });

    $('form#checkout').on('click', 'a.place-order:not(.disabled)', function (e) {
      e.preventDefault();

      try {
        __adroll.record_user(
          {'adroll_segments': 'conversion'}
        );
      } catch (err) {
        // Continue
      }

      $(this).addClass('disabled');
      var $form = $(this).closest('form');
      $form.submit();
    });
  };

  SC.instructors_index = function () {
  };

  SC.studio_position_apply = function () {
    $('form#application a.form-submit').click(function (e) {
      e.preventDefault();
      var $form = $(this).closest('form');
      // Only submit once
      if ($form.hasClass('submitting')) {
        return;
      }
      $form.addClass('submitting');
      var formData = {};
      $form.find('select:hidden').each(function () {
        var $s = $(this);
        var selectName = $s.attr('name');
        formData[selectName] = $s.val();
      });
      $form.ajaxSubmit({
        data: formData,
        dataType: 'json',
        success: function (data) {
          $form.updateCsrfToken();
          $form.removeClass('submitting');
          if (data.success) {
            window.location.href = data.redirectUrl;
            return;
          }
          $form.clearFormErrors();
          if (data.fieldErrors) {
            $form.fieldErrors(data.fieldErrors);
            $form.flagFieldErrorsWithFileInputs();
          } else {
            var message = data.message ? data.message : genericErrorMessage;
            confirmation(undefined, message);
          }
        },
        error: function () {
          $form.updateCsrfToken();
          $form.removeClass('submitting');
          confirmation(undefined, genericErrorMessage);
        }
      });
    });
  };

  SC.community_detail = function () {
    var $textarea = $('.comment-reply-textarea');
    var textareaInitialHeight = $textarea.outerHeight();

    $textarea.on('focus', function () {
      $textarea.on('keyup.autoresize', function () {
        $textarea.outerHeight(textareaInitialHeight);
        var realScrollHeight = $textarea[0].scrollHeight < textareaInitialHeight ? textareaInitialHeight : $textarea[0].scrollHeight + 10;
        if (realScrollHeight > textareaInitialHeight - 1) {
          $textarea.outerHeight(realScrollHeight + 12 + 'px');
        } else {
          $textarea.outerHeight(realScrollHeight + 0 + 'px');
        }
      });
    }).on('blur', function () {
      $textarea.off('.autoresize');
      if (!$textarea.val()) {
        $textarea.outerHeight(textareaInitialHeight);
      }
    });

    var $composeForm = $('.comment-compose form');
    $composeForm.on('submit', function (e) {
      e.preventDefault();

      $textarea = $(this).find('textarea');
      if (!$.trim($textarea.val())) {return;}

      var url = $(this).attr('action');
      var data = {
        community_id: $(this).find('input[name=community_id]').val(),
        comment: $textarea.val()
      };
      var success = function (data) {
        if (data.success) {
          // create & append comment
          var $comment = $('.comment-entry.template').clone().removeClass('template').show();
          var sc = window.soulcycle;
          var name = sc.rider.first_name + ' ' + sc.rider.last_name.substring(0, 1) + '.';
          // $comment.find('.author-thumb img').attr('src', thumb);
          $comment.find('.comment-author').html(name);
          $comment.find('.comment-text').html(data.comment);
          $comment.hide().insertAfter('.comments-container .comment-compose').fadeIn('fast');

          $textarea.val('');
        } else {
          // indicate
        }
      };
      $.post(url, data, success, 'json');
    });

    var $composeSubmit = $('.comment-compose .form-button');
    $composeSubmit.on('mouseup', function () {
      var $form = $(this).closest('form');
      $form.submit();
    });
  };

  /** Run initialization functions by body class keys */
  var bodyClasses = $('body').attr('class').split(' ');
  $.each(bodyClasses, function (idx, className) {
    if (SC.hasOwnProperty(className)) {
      SC[className]();
    }
  });

  /**
   * "Me" page events
   */
  if ($('.profile-grid-container').length) {
    setTimeout(function () {
      registerCountryDropdownEvents();
    }, 100);
  }

  /**
   * Global Events
   */
  var $dropdownChangeRegion = $('.dropdown-nav .change-region');
  var $footerChangeRegion = $('.footer-container').find('.change-region');

  $dropdownChangeRegion.click(function (e) {
    e.preventDefault();
    if ($footerChangeRegion.hasClass('active')) {
      $footerChangeRegion.removeClass('active');
    } else {
      $footerChangeRegion.addClass('active');
    }
  });

  // Footer change-region click toggle logic
  $footerChangeRegion.click(function (e) {
    e.preventDefault();
    if ($footerChangeRegion.hasClass('active')) {
      $footerChangeRegion.removeClass('active');
    } else {
      $footerChangeRegion.addClass('active');
    }
  });

  // Control all instances of tabs on footer page layouts, following class naming structure used below
  $('.footer-sections').find('.section-tabs').find('li').on('click', function (e) {
    e.preventDefault();

    var $this = $(this);
    var index = $this.index();
    var $sectionRowContainer = $('.footer-sections').find('.section-row-container');

    $this.siblings().removeClass('active');
    $this.addClass('active');

    $sectionRowContainer.removeClass('active');
    $sectionRowContainer.eq(index).addClass('active');
  });

  // for ccv tooltip
  var ccvHelpIcon = document.querySelector('#ccv-help .icon');

  if (ccvHelpIcon !== null) {
    var ccvCLickHandler = function () {
      var tooltip = document.getElementById('ccv-tooltip');

      if (tooltip.classList.contains('block')) {
        tooltip.classList.remove('block');
        tooltip.setAttribute('aria-hidden', 'false');
      } else {
        tooltip.classList.add('block');
        tooltip.setAttribute('aria-hidden', 'true');
      }
    };

    enquire.register('screen and (max-width:1024px)', {
      match: function () {
        var ccvHelp = document.getElementById('ccv-help');

        ccvHelp.addEventListener('click', ccvCLickHandler);
      },

      unmatch: function () {
        var ccvHelp = document.getElementById('ccv-help');

        ccvHelp.removeEventListener('click', ccvCLickHandler);
      }
    });

    var tooltip = document.getElementById('ccv-tooltip');

    ccvHelpIcon.addEventListener('focus', function () {
      // timeout needed to prevent focus and click to interfere with each other
      setTimeout(function () {
        tooltip.classList.add('block');
      }, 100);
      tooltip.setAttribute('aria-hidden', 'false');
    });

    ccvHelpIcon.addEventListener('blur', function () {
      tooltip.classList.remove('block');
      tooltip.setAttribute('aria-hidden', 'true');
    });

    ccvHelpIcon.addEventListener('mouseenter', function () {
      tooltip.setAttribute('aria-hidden', 'false');
    });

    ccvHelpIcon.addEventListener('mouseleave', function () {
      if (tooltip.classList.contains('block')) {
        return;
      }
      tooltip.setAttribute('aria-hidden', 'true');
    });
  }

  // Set and validate phone number format.
  var $phoneNumberIntl = $('.phone-number-intl');
  if ($phoneNumberIntl.length) {
    var countryCode = window.soulcycle.countryCode === '' ? 'US' : window.soulcycle.countryCode.toUpperCase();

    $phoneNumberIntl.intlTelInput({
      autoPlaceholder: 'aggressive',
      initialCountry: countryCode,
      nationalMode: false,
      preferredCountries: ['us', 'ca', 'gb']
    });

    // Accessibility feature for intltelinput plugin
    $phoneNumberIntl.on('open:countrydropdown', function () {
      var $intlTelInputParent = $(this).parent();
      $intlTelInputParent.find('.selected-flag').attr('aria-expanded', 'true');
      $intlTelInputParent.find('.country-list li').first().focus();
    });

    $phoneNumberIntl.on('close:countrydropdown', function () {
      $(this).parent().find('.selected-flag').attr('aria-expanded', 'false');
    });

    $phoneNumberIntl.on('countrychange', function (e, countryData) {
      if (countryData.iso2) {
        $(this).parent().find('.selected-flag').attr('aria-label', 'select country - ' + countryData.iso2.toUpperCase());
      }
    });

    // Set default ARIA attributes to intl inputs
    var $intlTelInput = $('.intl-tel-input');
    $intlTelInput.each(function (index) {
      var $currentInput = $(this);
      var $currentPhoneNumberIntl = $currentInput.find('.phone-number-intl');

      // We need to set tab index to the same original input tabindex
      var tabindex;
      if ($currentPhoneNumberIntl.attr('tabindex')) {
        tabindex = $currentPhoneNumberIntl.attr('tabindex');
      } else {
        tabindex = '0';
      }

      $currentInput.find('.selected-flag').attr('role', 'button')
        .attr('tabindex', tabindex)
        .attr('aria-haspopup', 'true')
        .attr('aria-label', 'select country - ' + countryCode)
        .attr('aria-expanded', 'false')
        .attr('aria-controls', 'country_list_' + index);

      $currentInput.find('.country-list').attr('id', 'country_list_' + index);
      $currentInput.find('.country-list .country').attr('tabindex', '0')
        .attr('role', 'option');
    });

    // Update focus to the country list when use the keyboard arrows UP and DOWN
    $intlTelInput.find('.flag-container').on('keydown', function (e) {
      // Set timeout to wait for the keydown event of the plugin
      setTimeout(function () {
        var $flagContainer = $(this);
        var isDropdownHidden = $flagContainer.find('.country-list').hasClass('hide');
        if (!isDropdownHidden && (e.type === 'keydown' || e.type === 'keyup')) {
          $flagContainer.find('.country-list .country.highlight').focus();
        }
      }.bind(this), 50);
    });
  }

  /**
   * Create confirm dialog object
   */
  var $confirmElm = $('.confirm-dialog-container');
  var parameters = {
    $element: $confirmElm,
    buttons: {
      accept: {
        $element: $confirmElm.find('.confirm-dialog-accept')
      },
      decline: {
        $element: $confirmElm.find('.confirm-dialog-decline')
      },
      close: {
        $element: $confirmElm.find('.confirm-dialog-close')
      }
    },
    $overlay: $confirmElm.find('.confirm-dialog-overlay'),
    textContainers: {
      banner: {
        $element: $confirmElm.find('.confirm-dialog__banner')
      },
      header: {
        $element: $confirmElm.find('.confirm-dialog-header')
      },
      main: {
        $element: $confirmElm.find('.confirm-dialog-text-main')
      },
      disclaimer: {
        $element: $confirmElm.find('.confirm-dialog-text-disclaimer')
      }
    }
  };

  SC.confirmDialog = new ConfirmDialog(parameters);

  /**
   * Email capture
   */
  var $emailCapture = $('#email-capture');
  var $emailCaptureInput = $emailCapture.find('#email-capture-input');
  var $emailCaptureHeadingButton = $emailCapture.find('.email-capture-heading-button');

  $emailCaptureHeadingButton.on('click', function () {
    var emailCaptureClosedClass = 'email-capture-closed';
    var emailCaptureClosedClasses = emailCaptureClosedClass + ' email-capture-closed-animation';
    var emailCaptureOpenClass = 'email-capture-open-animation';
    var emailCaptureCompletedClass = 'email-capture-completed';
    var emailCaptureEventData = {
      'Context': 'Email Capture',
      'Location': 'Navigation'
    };

    if ($emailCapture.hasClass(emailCaptureCompletedClass)) {
      SC.emailCapture.remove();
    } else if (!$emailCapture.hasClass(emailCaptureClosedClass)) {
      SC.analytics.mp.sendEvent('Click Close', 'navigation', emailCaptureEventData, 'Email Capture');
      SC.utils.setCookie('SC_EMAIL_CAPTURE_STATE', 'closed', 0);
      $emailCapture.removeClass(emailCaptureOpenClass).addClass(emailCaptureClosedClasses);
      $emailCaptureHeadingButton.attr('aria-label', 'Open email subscription form');
    } else {
      SC.analytics.mp.sendEvent('Click Open', 'navigation', emailCaptureEventData, 'Email Capture');
      SC.utils.deleteCookie('SC_EMAIL_CAPTURE_STATE');
      $emailCapture.removeClass(emailCaptureClosedClasses).addClass(emailCaptureOpenClass);
      $emailCaptureHeadingButton.attr('aria-label', 'Close email subscription form');
    }
  });

  $('.email-capture-form').on('submit', function (e) {
    e.preventDefault();
    var emailAddress = $emailCapture.find('#email-capture-input').val();
    var $form = $(this).closest('form');
    SC.emailCapture.submit(emailAddress, $form);
  });

  $emailCaptureInput.on('input', function () {
    var enableValue = SC.utils.validation.isEmail($emailCaptureInput.val());
    SC.emailCapture.enableSubmit(enableValue);
  });
});
