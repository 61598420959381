/**
 * Wrapper function to set up a proTip modal on a page.
 * Returns a new confirmDialog object with the pro tip HTML.
 *
 * Usage:
 * SC.proTip = new proTip() - Setup the base Pro Tip modal
 * SC.proTip.confirm(parameters) - Set the text content for the modal.
 *
 * @returns {ConfirmDialog} dialog object
 */
// eslint-disable-next-line no-unused-vars
function proTip() {
  var $proTip = $('.pro-tip-container');

  // Check if object fit is available. If its not its an old browser
  // Set the position because flex does not work the same.
  if (!Modernizr.objectfit) {
    $proTip.css({'left': '50%', 'margin-left': '-25%'});
  }

  var parameters = {
    $element: $proTip,
    buttons: {
      dismiss: {
        $element: $proTip.find('.pro-tip__button')
      }
    },
    textContainers: {
      header: {
        $element: $proTip.find('.pro-tip__title')
      },
      main: {
        $element: $proTip.find('.pro-tip__message')
      }
    }
  };

  return new ConfirmDialog(parameters);
}
