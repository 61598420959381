'use strict';

var ntl = {};
ntl.form = {};
ntl.messages = {};

ntl.form.rules = {
  ignore: '.ignore',
  errorClass: 'error',
  validClass: 'valid',
  focusCleanup: true,
  focusInvalid: true,
  debug: true,
  first_name: {
    required: true,
    minlength: 1
  },
  last_name: {
    required: true,
    minlength: 1
  },
  phone: {
    required: true,
    phoneUS: true
  },
  username: {
    required: true,
    minlength: 2
  },
  password: {
    required: true,
    minlength: 5
  },
  confirm_password: {
    required: true,
    minlength: 5,
    equalTo: '.password'
  },
  new_reg_email: {
    required: true,
    email: true,
    remote: {
      url: '/soul-revolution/',
      type: 'post',
      data: {
        csrf: function () {
          return $('#csrf');
        },
        email: function () {
          return $('#email').val();
        }
      }
    }
  },
  email: {
    required: true,
    email: true
  },
  address_1: 'required',
  city: 'required',
  state: 'required',
  zip: 'required',
  emergency_contact_name: 'required',
  emergency_contact_phone: {
    required: true,
    digits: true
  }
};

ntl.validate = function (formId) {
  ntl.addClassRules();
  var opts = {
    rules: ntl.form.rules,
    messages: ntl.messages[$('html').attr('lang')]
  };

  return $('#' + formId).validate(opts);
};

ntl.addClassRules = function () {
  /*
  jQuery.validator.addClassRules('example',{
      required: true,
      minlength: 2,
      maxlength: 2
  })
  */
};
