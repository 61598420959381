var $confirmationCloseBtn = $('.confirmation-close-btn');
var animationDelay;

// eslint-disable-next-line no-unused-vars
function confirmation(_link, message, _buttons, _timeout, _linkback, _tag, _extraClasses) {
  var $confirmation = $('.confirmation');
  var stickyClass = 'confirmation-sticky';

  $confirmation.stop(true, true);
  $confirmation.clearQueue();
  $confirmation.removeClass('show');
  $confirmation.find('.show').removeClass('show');
  $confirmation.removeAttr('style');
  $confirmation.removeClass(stickyClass);

  // timeout should be in seconds, pass timeout = 0 for infinite
  var timeout = typeof _timeout !== 'undefined' && !isNaN(_timeout) ? _timeout * 1000 : 10000;
  var linkback = _linkback && _linkback !== '#' ? _linkback : false;
  var link = _link || '#';
  var tag = tag || '';
  var extraClasses = _extraClasses || '';

  $confirmation.find('.message').empty().html(message);

  var $callToAction = $confirmation.find('.confirmation-call-to-action');

  if (_buttons) {
    var buttons = _buttons;

    if (typeof _buttons === 'string') {
      buttons = {
        ok: {
          label: _buttons,
          link: link,
          linkback: linkback,
          tag: tag,
          extra_classes: extraClasses
        }
      };
    }


    if (!$callToAction.hasClass('show')) {
      $callToAction.addClass('show');
    }

    // need to break the confirmation apart to be an alert or a confirmation
    // $confirmation.find('.confirmation-type-indicator').addClass('show');

    var $buttons = $('<div/>');

    for (var b in buttons) {
      if (!buttons.hasOwnProperty(b)) {
        continue;
      }

      var btn = buttons[b];

      var $button = $('<span/>');
      $button.addClass('buttons');

      var $a = $('<a/>');
      $a.attr('href', btn.link ? btn.link : '#');
      $a.attr('accesskey', 'k');
      $a.html(btn.label);

      $button.addClass('action-button');
      $a.addClass('small-button gray-button-dark action-button-label');

      if (btn.linkback) {
        $a.attr('data-linkback', btn.linkback);
      }
      if (btn.tag) {
        $a.attr('data-tag', btn.tag);
      }
      if (btn.extra_classes) {
        $a.addClass(btn.extra_classes);
      }
      $button.append($a);
      $button.addClass('show');

      // excecute callback on button
      if (typeof btn.callback === 'function') {
        $a.on('click', btn.callback);
      }

      $buttons.append($button);
    }

    $callToAction.empty().append($buttons);
  }

  var confirmationHeight = $confirmation.height();

  /**
     * passive alert representation
     * ============================
     * where are we on the page should matter
     */
  var $body = $('body');
  var $scContainer = $('.soul-container');
  var headerAuthHeight = 330;

  if ($body.scrollTop() >= headerAuthHeight && $scContainer.hasClass('auth-open')) {
    $confirmation.addClass(stickyClass);
  }

  // add event listener to scroll
  $(window).scroll(function () {
    var scroller = $(this).scrollTop();
    if (scroller > headerAuthHeight) {
      if (!$confirmation.hasClass(stickyClass)) {
        $confirmation.addClass(stickyClass);
      }
    } else {
      $confirmation.removeClass(stickyClass);
    }
  });

  $confirmation.css('height', 0);

  setTimeout(function () {
    $confirmation.css({'height': confirmationHeight + 'px', 'visibility': 'visible'}).addClass('show');
  }, 0);

  if (timeout > 0) {
    animationDelay = setTimeout(function () {
      $confirmation.css({'height': 0, 'visibility': 'hidden'}).removeClass('show');
    }, timeout);
  }
}

$confirmationCloseBtn.on('click', function (e) {
  e.preventDefault();
  clearTimeout(animationDelay);
  $('.confirmation').css({'height': 0, 'visibility': 'hidden'}).removeClass('show');
});

window.addEventListener('load', function () {
  var confirmationMessage = document.querySelector('.confirmation.show .confirmation-message');
  if (confirmationMessage) {
    confirmationMessage.focus();
  }
});

// eslint-disable-next-line no-unused-vars
function closeConfirmation() {
  $confirmationCloseBtn.trigger('click');
}
