/**
 *
 * @param {object} parameters an object that consists of the confirm dialog's initial settings.
 *
 *      Ex:
 *      {
 *           //Confirm dialog outermost element as jQuery object
 *           $element:  $confirmElm,
 *
 *           //List of controlled buttons within dialog indexed by their type/name
 *           buttons: {
 *                  accept: {
 *                      //jQuery object of button element
 *                      $element: $confirmElm.find('.confirm-dialog-accept')
 *                  },
 *                  decline: {
 *                      $element: $confirmElm.find('.confirm-dialog-decline')
 *                  },
 *                  close: {
 *                     $element: $confirmElm.find('.confirm-dialog-close')
 *                  }
 *              },
 *
 *           //jQuery object for overlay element
 *           $overlay: $confirmElm.find('.confirm-dialog-overlay'),
 *
 *           //List of controlled text containers within dialog indexed by their type/name
 *           textContainers: {
 *              header: {
 *                  //jQuery object of text container
 *                  $element: $confirmElm.find('.confirm-dialog-header')
 *              },
 *              main: {
 *                  $element: $confirmElm.find('.confirm-dialog-text-main')
 *              }
 *           }
 *      }
 * @returns {void}
 *
 */
// eslint-disable-next-line no-unused-vars
function ConfirmDialog(parameters) {
  var self = this;

  this.$element = parameters.$element;
  this.$overlay = parameters.$overlay;
  this.buttons = parameters.buttons;
  this.textContainers = parameters.textContainers;

  this.show = function () {
    this.$element.show();
  };

  this.hide = function () {
    this.$element.hide();
  };

  this.init = function () {
    // If there is an overlay, setup overlay event to hide the dialog.
    if (this.$overlay) {
      this.$overlay.on('click keyup', function (e) {
        if (SC.utils.ui.handleKeyupEvent(e)) {
          self.hide();
        }
      });
    }

    // Button events.
    // All button events hide the dialog and execute callback if one exists
    for (var buttonType in this.buttons) {
      if (!this.buttons.hasOwnProperty(buttonType)) {
        continue;
      }
       
      (function () {
        var button = self.buttons[buttonType];

        button.$element.on('click keyup', function (e) {
          if (SC.utils.ui.handleKeyupEvent(e)) {
            self.hide();

            if (button.callback) {
              button.callback();
            }
          }
        });
      })();
    }
  };

  // Restore defaults
  this.setDefaults = function () {
    for (var buttonType in this.buttons) {
      if (!this.buttons.hasOwnProperty(buttonType)) {
        continue;
      }

      this.buttons[buttonType].callback = false;
    }

    for (var textType in this.textContainers) {
      if (!this.textContainers.hasOwnProperty(textType)) {
        continue;
      }

      // All text containers are hidden by default
      this.textContainers[textType].$element.empty().addClass('hidden');
    }
  };

  /*
     * The function that actually executes the confirm dialog functionality
     *
     * @param parameters an object that consists of settings for buttons and text containers
     *
     * Ex
     *  {
     *      //Object for button settings.  The indexes should mirror the ones that were instantiated with the object
     *      buttons: {
     *          accept: {
     *              //Desired text for the button
     *              text: 'accept',
     *
     *              //Optional callback for the button
     *              callback: function() {
     *                  //Do Stuff
     *              }
     *           },
     *           decline: {
     *              text: 'nevermind'
     *           }
     *       },
     *       //Object for text container settings.  The indexes should mirror the ones that were instantiated with the object
     *       textContainers: {
     *          header: {
     *              //Desired text/html to display in the text container
     *              text: "header text"
     *          },
     *          main: {
     *              text: 'more text'
     *          }
     *      }
     * }
     */
  this.confirm = function (parameters) {
    // Reset to defaults
    this.setDefaults();
    var error;

    if (parameters.buttons) {
      for (var buttonType in parameters.buttons) {
        if (!parameters.buttons.hasOwnProperty(buttonType)) {
          continue;
        }

        // If the button type was not instantiated with the object throw an error
        if (!this.buttons[buttonType]) {
          error = 'Button ' + buttonType + ' does not exist';
          throw error;
        }

        // Assign custom callback to the button's callback property
        if (parameters.buttons[buttonType].callback) {
          this.buttons[buttonType].callback = parameters.buttons[buttonType].callback;
        }

        // Fill button's element with custom text
        this.buttons[buttonType].$element.html(parameters.buttons[buttonType].text);
      }
    }

    if (parameters.textContainers) {
      for (var textType in parameters.textContainers) {
        if (!parameters.textContainers.hasOwnProperty(textType)) {
          continue;
        }

        // If the text container type was not instantiated with the object throw an error
        if (!this.textContainers[textType]) {
          error = 'Text container ' + textType + ' does not exist';
          throw error;
        }

        // Fill text container with custom text/html and remove the hidden class
        this.textContainers[textType].$element
          .html(parameters.textContainers[textType].text)
          .removeClass('hidden');
      }
    }

    // Display dialog
    this.show();
  };

  this.init();
}

