'use strict';

SC.emailCapture = (function () {
  var api = {};
  var $emailCapture = $('#email-capture');
  var UTM_COOKIE_NAME = 'SC_EMAIL_CAPTURE_UTM';

  var trackUTMAsAttributes = function () {
    // Only track on non-logged-in users
    if (soulcycle.rider.id) {
      return;
    }

    var query = document.location.search.replace(/^\?/, '');
    if (!query) {
      return;
    }

    SC.utils.setCookie(UTM_COOKIE_NAME, query, 0);
    query.split('&').forEach(function (val) {
      var tokens = val.split('=');
      var name = tokens[0];
      var value = tokens[1];
      SC.analytics.mp.userEvent('attribute', value, name);
    });
  };

  var init = function () {
    // Wait for mParticle Identification to be done prior to tracking UTM
    if (SC.utils && SC.utils.pubsub) {
      SC.utils.pubsub.subscribe('mParticle:identity-success', trackUTMAsAttributes);
    }
  };

  /**
   * Remove email capture
   *
   * @returns {void}
   */
  api.remove = function remove() {
    SC.utils.setCookie('SC_EMAIL_CAPTURE_STATE', 'removed');
    SC.utils.deleteCookie(UTM_COOKIE_NAME);
    $emailCapture.remove();
  };

  /**
   * Handle email capture submit - WIP
   *
   * @param {string} email email address
   * @param {Object} $form email capture form
   * @returns {void}
   */
  api.submit = function submit(email, $form) {
    api.enableSubmit(false);
    $form.clearFormErrors();
    $form.ajaxSubmit({
      data: {
        location: window.location.host + window.location.pathname
      },
      dataType: 'json',
      success: function onSuccess() {
        var $emailCaptureHeadingButton = $emailCapture.find('.email-capture-heading-button');

        $form.updateCsrfToken();
        // Associate email via mParticle
        SC.analytics.mp.userEvent('attribute', email, 'email');
        SC.analytics.mp.userEvent('attribute', 1, 'email-captured');
        SC.analytics.mp.userEvent('attribute', 'webemailsignup', 'lp_name');

        /**
         * mparticle sendEvent - log in successful
         */
        var eventData = {
          'Response': 'Success',
          'Location': 'Navigation'
        };

        SC.analytics.mp.sendEvent('Sign Up Email Capture', 'navigation', eventData, 'Email Capture');
        $emailCapture.addClass('email-capture-completed');
        $emailCaptureHeadingButton.attr('aria-label', 'Close email subscription confirmation');
        SC.utils.setCookie('SC_EMAIL_CAPTURE_STATE', 'removed');
        setTimeout(api.remove, 10000); // remove email capture after 10 seconds when completed
      },
      error: function onError(response, status, errorThrown) {
        var json = response.responseJSON;
        var errorMessage = json && json.error || genericErrorMessage;
        var $emailCaptureInput = $emailCapture.find('#email-capture-input');

        /**
         * mparticle sendEvent - log in failure
         */
        var eventData = {
          'Response': 'Submit error - status: ' + status + ', form error: ' + errorMessage + ', server error: ' + errorThrown,
          'Location': 'Navigation'
        };

        $form.updateCsrfToken();
        SC.analytics.mp.sendEvent('Sign Up Email Capture', 'navigation', eventData, 'Email Capture');
        $emailCaptureInput.attr('aria-invalid', true);
        api.showError(errorMessage);
      }
    });
  };

  /**
   * Show email capture form error
   *
   * @param {string} message error message
   * @returns {void}
   */
  api.showError = function showError(message) {
    var input = $emailCapture.find('#email-capture-input');
    var formError = $emailCapture.find('.email-capture-form-error');

    input.addClass('email-capture-form-input-error');
    formError.text(message);
    formError.show();
  };

  /**
   * Enable form submit button
   *
   * @param {boolean} enable enable if true, disable if false
   * @returns {void}
   */
  api.enableSubmit = function enableSubmit(enable) {
    var submitButton = $emailCapture.find('#email-capture-form-container-submit');

    submitButton.prop('disabled', !enable);
    submitButton.toggleClass('email-capture-form-footer-submit-button-enabled', !!enable);
  };

  init();

  return api;
})();
