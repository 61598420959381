SC.authentication = (function () {
  var api = {};

  /**
   * Initialization function which runs at object instantiation time.
   * @returns {void}
   */
  var init = function () {
    bindEvents();
    trackAuthEvents();
  };

  var customInputNames = {
    'password': 'Password',
    'confirmpassword': 'Confirmation Password',
    'email': 'Email',
    'phone': 'Phone',
    'emergency_contact_phone': 'Emergency Contact Number'
  };

  var signupFlow1Label = 'Sign Up Flow 1 (Spread the Soul)';
  var signupFlow2Label = 'Sign Up Flow 2 (Navigation Bar)';
  var signupFlow3Label = 'Sign Up Flow 3 (Check Out)';

  var trackFormInputs = function (label, event) {
    var $inputElement    = $(event.target);
    var inputName        = $inputElement.attr('name');
    var inputPlaceholder = $inputElement.attr('placeholder');
    var inputEventName   = customInputNames[inputName] || inputPlaceholder;

    var customLabels = {
      'Google.Category': 'Sign Up',
      'Google.Label': label
    };

    // mparticle logEvent - Fill input field
    SC.analytics.mp.logEvent('Fill ' + inputEventName, 'navigation', null, customLabels);
  };

  var trackAuthEvents = function () {
    var $joinFormInputs               = $('.join-now form input');
    var $checkoutSignupInputs         = $('.checkout_landing .form-signup input');
    var $riderReferralSignUpContainer = $('.rr-sign-up-container input');

    // Data tagging Track Signup Input Fields
    $riderReferralSignUpContainer.change(trackFormInputs.bind(null, signupFlow1Label));
    $joinFormInputs.change(trackFormInputs.bind(null, signupFlow2Label));
    $checkoutSignupInputs.change(trackFormInputs.bind(null, signupFlow3Label));

    // Track Create Account (From Checking Out) Sign Up Flow 3 (Check Out)
    $('.expand-create-account-form').click(function () {
      var customLabels = {
        'Google.Category': 'Sign Up',
        'Google.Label': signupFlow3Label
      };
      var eventName = 'Create Account (From Checking Out)';
      SC.analytics.mp.logEvent(eventName, 'navigation', null, customLabels);
    });

    // Track Click Check out the FAQ! Sign Up Flow 1 (Spread the Soul)
    $('.rr-more-info a').click(function () {
      var eventName = 'Click Check Out the FAQ';

      var customLabels = {
        'Google.Category': 'Sign Up',
        'Google.Label': signupFlow1Label
      };

      SC.analytics.mp.logEvent(eventName, 'navigation', null, customLabels);
    });
  };

  /**
   * Bind events to elements on navigation
   * @returns {void}
   */
  var bindEvents = function () {
    var $soulContainer = $('.soul-container');
    var $globalForm = $('.global__form');
    var $loginFormContainer = $('.login-container');
    var $forgotPasswordContainer = $('.forgot-password-container');
    var $corporateSignupContainer = $('.corporate-signup');
    var $checkoutCreateSection = $('.checkout-create-section');
    var $shoppingBagContainer = $('.shopping-bag-container');
    var $rrAuthentication = $('.rr-authentication');
    var $signupInputs = $('.form-join-now input');
    var $globalInputs = $('.global__input');

    $globalInputs.each(function () {
      if ($(this).val() !== '') {
        addShowClassOnAutoFill.apply(this);
      }
    });

    var customLabelSignupFlow2 = {
      'Google.Category': 'Sign Up',
      'Google.Label': signupFlow2Label
    };

    api.$soulContainer = $soulContainer;

    /** Header / Authentication Events */
    $soulContainer.on('submit', 'form.form-sign-in', function (e) {
      e.preventDefault();
      var $form = $(this);

      toggleThrobber(true);
      $(this).ajaxSubmit({
        dataType: 'json',
        success: function (data) {
          $form.updateCsrfToken();
          var eventData;

          if (data.success) {
            // SC.analytics.branch.setIdentity returns a promise once the identity has been set
            var branchPromise = SC.analytics.branch.setIdentity(data.rider.id);

            /**
             * mparticle user identity setup
             */
            SC.analytics.mp.userEvent('remove');
            var userEventPromise = SC.analytics.mp.userEvent('setup', data.rider.id.toString());

            userEventPromise.then(function () {
              // Set mp events only when the response is correct.
              SC.analytics.mp.userEvent('attribute', data.rider.first_name, 'first_name');

              /**
               * mparticle sendEvent - log in successful
               */
              eventData = {
                'Response': 'Success',
                'Location': 'Navigation'
              };
              SC.analytics.mp.sendEvent('Click Log In CTA', 'navigation', eventData, 'Navigation');
            });

            $soulContainer.trigger('onLogin');

            branchPromise.always(function () {
              userEventPromise.always(function () {
                if (data.redirect) {
                  location.href = data.redirect;
                } else {
                  location.reload();
                }
                return;
              });
            });
          } else {
            eventData = {
              'Response': 'Invalid email or password',
              'Location': 'Navigation'
            };
            SC.analytics.mp.sendEvent('Click Log In CTA', 'navigation', eventData, 'Navigation');
          }

          toggleThrobber(false);

          if (!data.success) {
            var error = data.message || genericErrorMessage;

            $form.formError(error);
          }
        },
        error: function (jqXHR, status, errorThrown) {
          var eventData = {
            'Response': 'Server Error - status: ' + status + ', error: ' + errorThrown,
            'Location': 'Navigation'
          };
          SC.analytics.mp.sendEvent('Click Log In CTA', 'navigation', eventData, 'Navigation');

          toggleThrobber(false);
          $form.updateCsrfToken();
          $form.formError(genericErrorMessage);
        }
      });
    });

    /**
     * mParticle Event - Logout Successful - Navigation
     */
    $soulContainer.on('click', '#nav-logout-link', function () {
      SC.analytics.mp.sendEvent('Log Out Successful', 'navigation', {'Location': 'Navigation'}, 'Navigation');
    });

    // The next time a handler is needed on a form, just give the form the 'global__form' class
    // Because autofill does not trigger any events, we monitor the transitionend event on
    // -webkit-autofill to signal a change in input
    $globalForm.on('keyup webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', '.global__input', addShowClassOnAutoFill);
    $corporateSignupContainer.on('keyup', '.field-row input', toggleShowClass);
    $checkoutCreateSection.on('keyup', '.field-row input', toggleShowClass);
    $rrAuthentication.on('keyup', '.field-row input', toggleShowClass);
    $shoppingBagContainer.on('keyup', '.giftcard-container input', toggleShowClass);
    $loginFormContainer.on('keyup', '.login__form__wrapper__input', toggleShowClass);
    $forgotPasswordContainer.on('keyup', '.forgot-password__form__wrapper__input', toggleShowClass);

    $corporateSignupContainer.on('focus', '.intl-tel-input input', addShowClass);
    $corporateSignupContainer.on('blur', '.intl-tel-input input', removeShowClass);

    $checkoutCreateSection.on('focus', '.intl-tel-input input', addShowClass);
    $checkoutCreateSection.on('blur', '.intl-tel-input input', removeShowClass);

    $rrAuthentication.on('focus', '.intl-tel-input input', addShowClass);
    $rrAuthentication.on('blur', '.intl-tel-input input', removeShowClass);

    // Terms and conditions - detect when rider scrolls to bottom of div
    $('.form_terms_and_conditions').on('scroll', function () {
      // Add the minus 1 to prevent issues with screen resolution in browser
      if ( Math.round($(this).scrollTop() + $(this).innerHeight()) >= ($(this)[0].scrollHeight - 1)) {
        // Ensure that the event gets fired only once.
        var termsCustomLabel = customLabelSignupFlow2;
        var signupFormName = $(this).data('current-signup-form');

        if ( signupFormName === 'rider_referral') {
          termsCustomLabel['Google.Label'] = signupFlow1Label;
        } else if (signupFormName === 'checkout_terms_and_conditions') {
          termsCustomLabel['Google.Label'] = signupFlow3Label;
        }

        /**
         * mparticle logEvent - User viewed all the terms and conditions.
         */
        SC.analytics.mp.logEvent('Scroll Terms & Conditions', 'navigation', null, termsCustomLabel);

        /**
         * Old GA sendEvent - User viewed all the terms and conditions.
         * Deprecated: Will be removed soon.
         */
        SC.analytics.ga.sendEvent('user', 'View', 'Scroll Terms & Conditions');
      }
    });

    // Progress to the next signup step when hitting enter at any time during rider signup
    $signupInputs.on('keypress', function (e) {
      e.stopPropagation();

      if (e.key === 'Enter') {
        e.preventDefault();
        $('.form-join-now > .active input.submit').click();
      }
    });

    /** End Header / Authentication Events */
  };

  var toggleShowClass = function () {
    var $label = $(this).next('label');
    if (this.value !== '') {
      $label.addClass('show');
    } else {
      $label.removeClass('show');
    }
  };

  var addShowClassOnAutoFill = function () {
    var $label = $(this).next('label');
    $label.addClass('show');
  };

  var addShowClass = function () {
    var $label = $(this).parent('.intl-tel-input').next('.frm-label, .swiper-slide__form__wrapper__label');
    setTimeout(function () {
      if (!$label.hasClass('show')) {
        $label.addClass('show');
      }
    }, 100);
  };

  var removeShowClass =  function () {
    var $label = $(this).parent('.intl-tel-input').next('.frm-label, .swiper-slide__form__wrapper__label');
    // this is to wait the country code get removed after losing focus
    setTimeout(function () {
      if ($label.hasClass('show') && this.value === '') {
        $label.removeClass('show');
      }
    }.bind(this), 100);
  };

  api.getTerms = function () {
    $.ajax({
      url: '/terms/',
      dataType: 'json',
      success: function (data) {
        if (data.success) {
          $('.form_terms_and_conditions').html(data.content);
          $('form.form-join-now .registration-agreement-id').val(data.agreement_id);
        } else {
          confirmation(null, 'Uh oh. Somethings not right. Please try again later.');
        }
      },
      error: function () {
        confirmation(null, 'Uh oh. Somethings not right. Please try that action again.');
      }
    });
  };

  init();
  return api;
})();
