SC.navigation = (function () {
  var api = {};
  var mainContainer = null; // the main content container to skip to
  var skipToMain = null; // the skip to main container button;

  /**
   * init
   * Initialization function which runs at object instantiation time.
   * - bindEvents: Bind Events related to the nav.
   * - Setup events to handle nav hover states.
   * - subnavFilters: Handle events in the subnav.
   * - analyticsEvents: Setup mParticle events related to the header navigation.
   * @returns {void}
   */
  var init = function () {
    mainContainer = document.getElementById('main-container');
    skipToMain = document.getElementById('skip-content');

    bindEvents();
    api.hoverEvents();
    subnavFilters();
    api.analyticsEvents();
  };

  var subnavFilters = function () {
    $('.subnav-title').click(function () {
      $('.subnav-dropdown').toggleClass('subnav-open');
    });

    $('.main-container .subnav-dropdown.alternate:not(.url) .subnav-filters a').click(function () {
      var navFilter = $(this);
      var active = 'active';
      var handle = '.' + navFilter.data('anchor-link');
      var title = navFilter.text();
      $('.subnav-title .title').empty().text(title);
      $('.main-container').find('.' + active).removeClass(active);

      $(handle).addClass(active);
      navFilter.addClass('active');

      $('.subnav-dropdown').removeClass('subnav-open');
    });
  };

  /**
   bindEvents
    Bind events to elements on navigation
    @returns {void}
    **/
  var bindEvents = function () {
    var $document = $(document);
    var $leftNav = $('.find-left-nav');
    var $rightNav = $('.find-right-nav');
    var $hoverHeight = $('.hover-height');
    var $nav = $('.nav0');
    var navRegionBtn = document.getElementById('nav-region-btn');
    var navFlagBtn = document.getElementById('nav-region-flag-btn');
    var $changeRegionLink = $('.change-region-link');

    if (skipToMain) {
      skipToMain.addEventListener('click', shiftFocus);
    }

    // Find/Buy Class event gets height of el and toggles class
    $document.on('click', '.find-left-nav .change-region-nav', function (e) {
      e.preventDefault();

      if ($nav.hasClass('swipe-left')) {
        return;
      }

      // Get height of nav elements
      var leftNavHeight = $leftNav.height();
      var rightNavHeight = $rightNav.height();

      $nav.height(rightNavHeight).addClass('swipe-left').removeClass('swipe-right');

      setTimeout(function () {
        $nav.addClass('swipe-left-completed').removeClass('swipe-right-completed');
        $('.find-right-nav .region-select-row .selectable-region a').first().focus();
      }, 200);

      navRegionBtn.setAttribute('aria-expanded', 'true');
      navFlagBtn.setAttribute('aria-expanded', 'true');
      $changeRegionLink.attr('aria-expanded', 'true');

      // Set Height of the hidden div to retain hover
      $hoverHeight.height(leftNavHeight);
    });

    $document.on('keydown', '.find-left-nav .change-region-nav', function (e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        $(this).trigger('click');
      }
    });

    // Find/Buy Class event gets height of el and toggles class
    $document.on('click', '.find-right-nav .active-region', function (e) {
      e.preventDefault();
      // Get height of nav elements
      var leftNavHeight = $leftNav.height();

      $nav.height(leftNavHeight).removeClass('swipe-left').addClass('swipe-right');

      setTimeout(function () {
        $nav.addClass('swipe-right-completed').removeClass('swipe-left-completed');
        $('.find-left-nav .find-a-class-row a').first().focus();
      }, 200);

      $hoverHeight.height(0);

      $changeRegionLink.attr('aria-expanded', 'false');
    });

    $document.on('keydown', '.find-right-nav .active-region, .find-right-nav .selectable-region', function (e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        $(this).trigger('click');
        $changeRegionLink.focus();
      }
    });

    $document.on('focus', '.active-region-title, .find-right-nav .selectable-region', function () {
      $('.find-left-nav .change-region-nav').trigger('click');
    });

    /**
     * mParticle sendEvent - Click Studio Page - Navigation
     * Sends an mParticle event with a custom GA Label
     */
    $document.on('click', '.find-a-class .find-a-class-row a', function () {
      SC.analytics.mp.sendEvent('Click Studio Page', 'navigation', {
        'Studio Name': $(this).text().trim(),
        'Context': 'Studio List',
        'Location': 'Navigation'
      }, 'Navigation');
    });

    /**
     * mParticle sendEvent - Click New Region - Navigation
     * Sends an mParticle event with a custom GA label.
     */
    $('.header-container').on('click', '.find-a-class .selectable-region a', function () {
      var regionName = $(this).text().trim();
      SC.analytics.mp.sendEvent('Click New Region', 'navigation', {
        'Region Name': regionName,
        'Location': 'Navigation'
      }, 'Navigation');
    });

    // Add click events to flag indicators to force open change region dropdown
    $('#mobile-region, #desktop-region').on('click', function () {
      if (!$('.find-a-class').hasClass('touch_hover')) {
        /**
         * mParticle sendEvent - Click Browsing Regions - Navigation (mobile and desktop)
         * Send an mParticle event along with a custom GA label.
         */
        SC.analytics.mp.sendEvent('Click Browsing Regions', 'navigation', {'Location': 'Navigation'}, 'Navigation');
      }

      $('.find-a-class-btn, .change-region-link').trigger('click');
      $('.mobile-nav-container').hide();
      $('.buy-series-btn.large-button.yellow-button').focus();
    });

    // Add keydown event to country-flag icon to force open region dropdown
    $('#desktop-region .country-flag.country-flags').on('keyup', function (e) {
      if (e.key === 'Enter' || e.key === ' ') {
        $('#desktop-region').click();
      }
    });

    // Change Region - using utility api.changeRegion functions
    $document.on('click', '.header-container .region-select-row li.selectable-region', function (e) {
      var $dataId = $(this).children('a').data('id');

      if ( Number($dataId) !== 0 ) {
        e.preventDefault();

        var $activeRegion = $('.header-container .active-region');
        var $selectedRegion = $(this).children('a');
        var $activeCountryISO = $activeRegion.attr('data-country-iso');
        var $activeCountryName = $('.header-container .region-select-row li a.active').attr('data-country-name');
        var $selectedCountryISO = $selectedRegion.attr('data-country-iso');
        var $selectedCountryName = $selectedRegion.attr('data-country-name');
        var $selectedRegionName = $selectedRegion.text();

        // check if selected country code matches active country code
        if ($activeCountryISO !== String($selectedCountryISO)) { // if different country check shop item counter
          var $shopCounter = $('.shop-counter').text();
          if ($shopCounter > 0) { // if something in bag then show prompt to clear bag
            var $modal = $('.global-modal');
            $modal.find('.title').html('Change Country?');
            $modal.find('.message').html('You have selected a region in ' + $selectedCountryName + '. Changing to ' + $selectedRegionName + ' will set your country to ' + $selectedCountryName
                            + ' and will clear your cart of any items you may have added when your country was set to ' + $activeCountryName +  '. Are you sure you wish to continue?');
            $modal.find('.button-cancel').html('cancel');
            $modal.find('.button-right').html('continue').addClass('continue').attr('data-region-id', $dataId);
            $modal.toggleClass('active');

            // if continue to country button is clicked, attempt to clear cart
            $('.confirmation-modal .button-right.continue').on('click', function () {
              // clear the shopping cart
              SC.utils.changeRegion($dataId, true, SC.utils.updateBubbleIndicatorClass);
            });
          } else {
            // change region, update country and don't clear cart
            SC.utils.changeRegion($dataId, true, SC.utils.updateBubbleIndicatorClass);
          }
        } else {
          // change region and don't clear cart
          SC.utils.changeRegion($dataId, false, SC.utils.updateBubbleIndicatorClass);
        }
      }
    });

    /** Header / Authentication Events */
    $document.on('click', 'form.toggle-transfer .transfer-toggle.button', function () {
      $(this).closest('form').submit();
    });

    /** Header / Global Modal Events */
    // Close global modal if background/cancel is clicked
    $('.confirmation-modal.global-modal, .confirmation-modal.global-modal .button-cancel').on('click', function () {
      $('.confirmation-modal.global-modal').toggleClass('active');
    });

    // Disable close of modal window if clicked
    $('.modal').click(function (e) {
      e.stopPropagation();
    });
  };

  /**
   hoverEvents
    events for nav items
    @returns {void}
    **/
  api.hoverEvents = function () {
    var $whiteOverlay = $('.white-overlay');
    var $document = $(document);
    var creditBtns = document.getElementById('credit-btns');
    // RIDER SERIES DROPDOWN
    // Note: Dropdown will not apply correctly if you cache $('.rider-series-dropdown .series-menu')
    // It is replaced with an ajax call to change Region and the cahced results are no longer valid

    $document.on('mouseenter', '.rider-series-dropdown .credits', function () {
      $(this).trigger('click');
    });

    // RIDER SERIES DROPDOWN MENU - HOVER
    $document.on('mouseleave', '.rider-series-dropdown .series-menu', function () {
      // Hide series dropdown
      $('.rider-series-dropdown .series-menu').removeClass('block');
      creditBtns.setAttribute('aria-expanded', 'false');
      $('body').removeClass('overflow-hidden');
      api.hideOverlay();
    });
    $document.on('mouseleave', '.rider-series-dropdown', function () {
      // Hide series dropdown
      $('.rider-series-dropdown .series-menu').removeClass('block');
      creditBtns.setAttribute('aria-expanded', 'false');
      $('body').removeClass('overflow-hidden');
      api.hideOverlay();
    });

    // RIDER SERIES DROPDOWN MENU - CLICK
    $document.on('click', '.rider-series-dropdown .credits', function () {
      $('.rider-series-dropdown .series-menu').addClass('block');
      creditBtns.setAttribute('aria-expanded', 'true');
      $('body').addClass('overflow-hidden');
      api.showOverlay();
    });

    $document.on('click', '.mobile-nav .credits', function () {
      $('.rider-series-dropdown .series-menu').addClass('block');
      creditBtns.setAttribute('aria-expanded', 'true');
      api.showOverlay();
    });

    // Add keydown event to open the rider-series-dropdown from the keyboard
    $document.on('keydown', '.indicator-container', function (e) {
      if (e.key === 'Enter' || e.key === ' ') {
        $('.rider-series-dropdown .series-menu').addClass('block');
        creditBtns.setAttribute('aria-expanded', 'true');
        api.showOverlay();
        $('.section-region').first().focus();
      }
    });

    // Add focus event to close the rider series modal
    $('.indicator-container').focus(function () {
      $('.rider-series-dropdown .series-menu').removeClass('block');
      creditBtns.setAttribute('aria-expanded', 'false');
    });

    $document.on('click keypress', '.rider-series-dropdown .buy-series-close-button', function (e) {
      // for keypress event only continue if key pressed is ENTER or SPACE
      if (e.type === 'keypress' && e.keyCode !== 32 && e.keyCode !== 13) {
        return;
      }

      $('.rider-series-dropdown .series-menu').removeClass('block');
      creditBtns.setAttribute('aria-expanded', 'false');
      $(this).removeClass('block');
      $('body').removeClass('overflow-hidden');
      $('.header-user-name-block .user-name').focus();
      api.hideOverlay();
    });


    // RIDER SERIES DROPDOWN MENU - REGION INFO TOGGLE
    $document.on('click', '.rider-series-dropdown .region', function () {
      $(this).siblings('.region-info').toggleClass('block');
      $(this).children().toggleClass('block');
    });

    // RIDER SERIES DROPDOWN MENU - TOGGLE SWITCH FOR SERIES TRANSFER
    $document.on('click keyup', '.switch', function (e) {
      e.preventDefault();

      // If the keyup event was not the enter key or spacebar, don't toggle series transfer.
      if (e.key !== 'Enter' && e.key !== ' ' && e.type === 'keyup') {
        return;
      }

      // @todo SmartProfile cleanup - Change the URL to the new SmartProfile URL
      $.ajax({
        method: 'post',
        url: '/profile/toggle-series-transfer/',
        data: $(this).closest('form').serialize()
      }).done(function (response) {
        SC.utils.ui.updateSeriesTransferMessage(response);
        confirmation(undefined, response.payload.message);
      }).fail(function (error) {
        console.log(error);
      });
    });

    $whiteOverlay.on('click', function () {
      api.hideOverlay();
    });

    // The class/studio region and the rider don't line up.
    // This triggers a special modal on the Find a Class and Select a Bike page.
    var riderClassRegionConflict = $('body').hasClass('rider-class-region-conflict');

    if (riderClassRegionConflict) {
      var $modal = $('.rider-class-region-conflict-modal');
      $modal.toggleClass('active');
      $('.rider-class-region-conflict-modal .button-change-regions').click(function () {
        $('.footer-container .change-region').toggleClass('active');
      });

      // Close modal if background/cancel is clicked
      $('.confirmation-modal.rider-class-region-conflict-modal, .rider-class-region-conflict-modal .button-cancel').click(function () {
        $('.confirmation-modal.rider-class-region-conflict-modal').toggleClass('active');
      });
    }
  };

  /**
   * mParticle Event - Click Navigation Link
   * Analytics events for clicks to Navigation Bar links.
   * Sends mParticle event with a label for GA event.
   *
   * GA Event Info:
   * Category: Navigation
   * Action: Click Navigation Link
   * Label: Link Name
   * @returns {void}
   */
  api.analyticsEvents = function analyticsEvents() {
    $('.top-level a').on('click', function (e) {
      var $link = $(e.target);
      var linkName = $link.data('link-name');
      var eventData = {
        'Location': 'Navigation',
        'Context': $link.closest('nav').data('context'),
        'Link Name': linkName
      };

      SC.analytics.mp.sendEvent('Click Navigation Link', 'navigation', eventData, linkName);
    });
  };

  /**
   * hoverEvents
   * Sets opacity to 1 and show overlay
   * @returns {void}
   */
  api.showOverlay = function () {
    $('.white-overlay').stop().animate({
      opacity: 1
    }, 150).show();
  };

  /**
   * hideOverlay
   * Sets opacity to 0 and hides overlay
   * @returns {void}
   */
  api.hideOverlay = function () {
    $('.white-overlay').stop().animate({
      opacity: 0
    }, 200, function () {
      $(this).hide();
    });
  };

  /**
   * shiftFocus shifts focus to the main container when the skip to main content button
   * is clicked.
   *
   * TODO: we may need to add multiple shifts of focus depending on context
   *
   * @param {object} e - the click event that triggered this function
   * @returns {void}
   */
  function shiftFocus(e) {
    // exit if for some reason the page we're on does not have a main container.
    if (!mainContainer) {
      return;
    }

    // prevent the default behavior on the click
    e.preventDefault();
    // shift focus to the main container.
    mainContainer.focus();
    // additionally scroll to the top to compensate for the fixed nav.
    window.scrollTo(0, 0);
  }

  init();
  return api;
})();
