SC = (function () {
  var api = {};

  var imageSizes = {
    'xs': {
      mq: 'screen and (max-width:599px)'
    },
    'sm': {
      mq: 'screen and (min-width:600px) and (max-width: 767px)'
    },
    'md': {
      mq: 'screen and (min-width:768px) and (max-width: 1023px)'
    },
    'lg': {
      mq: 'screen and (min-width:1024px)'
    }
  };

  // refreshes all images
  api.refreshImages = function () {
    loadNewImages('img');
    loadNewImages('.bgMq');
  };

  // Allows refreshing images in case of lazy loading images
  // Or adding img tags after resize/onload
  api.loadLazyImages = function () {
    loadNewImages('img:not(.image-loaded)');
  };

  var init = function () {
    if (!window.console) {
      // This does not seem really smart. Leaving this until we have more time to review
      // eslint-disable-next-line no-global-assign
      console = {
        log: function () {
        }
      };
    }

    // initialize enquire
    enquire
      .register('screen and (max-width:599px)', {
        deferSetup: true,
        match: function () {
          $('body').addClass('lightbox-repos');

          api.refreshImages();
        },
        unmatch: function () {
          $('body').removeClass('lightbox-repos');
        }
      })
      .register('screen and (min-width:600px) and (max-width: 767px)', {
        deferSetup: true,
        match: function () {
          api.refreshImages();
        }
      })
      .register('screen and (min-width:768px) and (max-width: 1023px)', {
        deferSetup: true,
        match: function () {
          api.refreshImages();
        }
      })
      .register('screen and (min-width:1024px)', {
        deferSetup: true,
        match: function () {
          api.refreshImages();

          $('body').removeClass('overflow-hidden');
        }
      })
      .register('screen and (min-width:1440px)', {
        deferSetup: true,
        match: function () {
          if ($('body').hasClass('our_story')) {
            $('.timeline-right').each(function () {
              var $this = $(this);
              var $timelineText = $this.find('.timeline-text').remove();
              $this.prepend($timelineText);
            });
          }
        }
      })
      .register('screen and (max-width:1339px)', {
        deferSetup: true,
        match: function () {
          if ($('body').hasClass('our_story')) {
            $('.timeline-right').each(function () {
              var $this = $(this);
              var $thumbnail = $this.find('.post-thumbnail').remove();
              $this.prepend($thumbnail);
            });
          }
        }
      });


    if ($('.soul-container').hasClass('confirmation-open')) {
      var $confirmation = $('.confirmation');
      var $msg = $confirmation.find('.message');
      var $buttonText = $confirmation.find('.action-button-label');
      confirmation(
        $buttonText.attr('href'),
        $msg.text(),
        $buttonText.text(),
        10,
        $buttonText.data('linkback'),
        $buttonText.data('tag')
      );
    }

    // TODO: move this to confirmation method
    $('.action-button-label').click(function () {
      var linkBack = $(this).data('linkback');
      if (linkBack) {
        // set cookie for link back with 5 minute cookie
        var d = new Date();
        d.setMinutes(d.getMinutes() + 5);
        document.cookie = 'linkback=' + linkBack + '; expires=' + d.toUTCString() + '; path=/';
      }
    });
  };

  /**
   * Execute responsive loading for images
   *
   * @param  {string} imagesSelector for images
   * @return {void}
   */
  function loadNewImages(imagesSelector) {
    if (!imagesSelector || typeof imagesSelector !== 'string') {
      return;
    }

    $(imagesSelector).each(function () {
      var $img = $(this);

      for (var sizeLabel in imageSizes) {
        if (!imageSizes.hasOwnProperty(sizeLabel)) {
          continue;
        }

        // Check the current media query and load image
        if (window.matchMedia(imageSizes[sizeLabel].mq).matches) {
          loadImage($img, sizeLabel);
          loadBackgroundImage($img, sizeLabel);
        }
      }
    });
  }

  /**
     * Load new image according to sizeLabel
     * and whether a media query attribute exists for a new source
     *
     * @param  {object} $img - jQuery object
     * @param  {string} sizeLabel - label
     * @return {void|object} - jQuery object
     */
  function loadImage($img, sizeLabel) {
    var dataAttrMq;

    // Check parameters
    if (!sizeLabel || !$img) {
      return;
    }

    dataAttrMq = 'data-mq-' + sizeLabel + '-src';

    // Check if there is a data attribute src for the sizeLabel
    if (!$img.attr(dataAttrMq) || !sizeLabel || !$img) {
      return;
    }

    // Load the image
    return $img
      .attr('src', $img.attr(dataAttrMq))
      .load(function () {
        $(this)
          .addClass('image-loaded');
      });
  }


  /**
     * Load new background image according to sizeLabel
     * and whether a media query attribute exists for a new source
     *
     * @param {object} $bgImg - jQuery object
     * @param {string} sizeLabel - label
     * @return {object} jQuery object
     */
  function loadBackgroundImage($bgImg, sizeLabel) {
    var dataAttrMq;

    // Check parameters
    if (!sizeLabel || !$bgImg) {
      return;
    }

    dataAttrMq = 'data-mq-' + sizeLabel + '-bg-src';

    // Check if there is a data attribute src for the sizeLabel
    if (!$bgImg.attr(dataAttrMq) || !sizeLabel || !$bgImg) {
      return;
    }

    // Load the image
    return $bgImg
      .css('background-image', 'url("' +  $bgImg.attr(dataAttrMq) + '")')
      .load(function () {
        $(this)
          .addClass('image-loaded');
      });
  }

  init();
  return api;
})();


SC.auth = (function () {
  var api = {};

  var init = function () {

  };

  init();
  return api;
})();
