/**
 * Wrapper function to set up a Success modal on a page.
 * Returns a new confirmDialog object with the Success Dialog HTML.
 *
 * Usage:
 * SC.successDialog = new successDialog() - Setup the base Success Dialog and attach to global SC object.
 * SC.successDialog.confirm(parameters) - Set the text content for the success dialog modal.
 *
 * @returns {ConfirmDialog} dialog object
 */
// eslint-disable-next-line no-unused-vars
function successDialog() {
  var $successDialog = $('.success-dialog-container');

  var parameters = {
    $element: $successDialog,
    buttons: {
      dismiss: {
        $element: $successDialog.find('.success-dialog__button')
      },
      close: {
        $element: $successDialog.find('.success-dialog__close')
      }
    },
    $overlay: $successDialog.find('.success-dialog-container__overlay'),
    textContainers: {
      header: {
        $element: $successDialog.find('.success-dialog__title')
      },
      main: {
        $element: $successDialog.find('.success-dialog__text')
      }
    }
  };

  return new ConfirmDialog(parameters);
}
